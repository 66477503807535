import * as axios from './../../axios'
import { pipe } from 'fp-ts/lib/function'
import * as effects from 'redux-saga/effects'
import * as t from 'io-ts'
import {
  FailableAction,
  failableActionCreator,
  memoed,
  Memoed,
  MemoedC,
} from './../types'
import { task } from 'fp-ts'
import { foldEitherToAction } from '@library/react-toolkit'

export const Platform = t.keyof(
  {
    sola: null,
    tpay: null,
    prosperus: null,
  },
  'Platform'
)
export type Platform = t.TypeOf<typeof Platform>

export const Env = t.keyof(
  {
    dev: null,
    preprod: null,
    prod: null,
  },
  'Env'
)
export type Env = t.TypeOf<typeof Env>

export const ApplicationInfo = t.type({
  env: Env,
  platform: Platform,
})
export type ApplicationInfo = t.TypeOf<typeof ApplicationInfo>

export type State = Memoed<ApplicationInfo>
export const StateC = MemoedC(ApplicationInfo)

export const initialState: State = memoed.init({
  env: 'prod',
  platform: 'tpay',
})

export type GetInfoResult = FailableAction<'get_info_result', ApplicationInfo>
export const setLocaleAction =
  failableActionCreator<GetInfoResult>('get_info_result')

export type Actions = GetInfoResult

export const reducer = (s = initialState, a: Actions): State =>
  a.type === 'get_info_result' ? memoed.step(a.payload)(s) : s

export function* infoInit(): Generator<any, void, any> {
  yield yield effects.call(
    pipe(
      axios.get('/management/info', { decoder: ApplicationInfo }),
      task.map(foldEitherToAction(setLocaleAction)),
      task.map((e) => effects.put(e))
    )
  )
}
