import axios from 'axios'
import { AMApprovalConfig, AMApprovalConfigBody } from './types'
import * as ax from '../../../../axios'

export const getUsers: any = (
  organizationName: string,
  page: number,
  itemsPerPage: number,
  data: any
) =>
  axios
    .get(
      `/uaa/api/organizations/${organizationName}/users?page=${page}&itemsPerPage=${itemsPerPage}`
    )
    .then((response) => {
      if (response.data.data.length < 1) {
        return data
      }
      data.push(...response.data.data)
      return getUsers(organizationName, page + 1, 50, data)
    })

export const upsertAMConfig = (
  {
    organizationName,
    minimum,
  }: { organizationName: string } & { minimum: AMApprovalConfigBody },
  cancelToken?: ax.CancelToken
) =>
  ax.post(
    `/assets-management/1.0/${organizationName}/approval-config`,
    minimum,
    {
      cancelToken,
      decoder: AMApprovalConfig,
    }
  )
