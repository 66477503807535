import * as t from 'io-ts'
import { Balance, eqBalance } from './Transaction'
import { eq, array } from 'fp-ts'

export const UserState = t.type({
  nym: t.string,
  balance: Balance,
  transferSequences: t.array(t.tuple([t.number, t.number])),
})

export type UserState = t.TypeOf<typeof UserState>

export const emptyUserState: Readonly<UserState> = {
  nym: '',
  balance: {},
  transferSequences: [],
}

export const eqUserState: eq.Eq<UserState> = eq.getStructEq({
  nym: eq.eqString,
  balance: eqBalance,
  transferSequences: array.getEq(eq.getTupleEq(eq.eqNumber, eq.eqNumber)),
})
