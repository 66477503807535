import * as React from 'react'

import { Button, Form, FormInstance } from 'antd'
import { TranslatedMessage } from '../../../shared/translations/data'
import { SignUp } from '../Login'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { loadable } from '@library/react-toolkit'

const ButtonBar = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
`

export type Props<T extends Partial<SignUp> = SignUp> = {
  initialValue: T
  onSubmit: (_: T) => void
  onChange: (_: Partial<T>) => void
  goBack?: () => void
  skip?: () => void
  finalStep?: boolean
  form?: FormInstance<T>
}

function SignUpStep<T extends Partial<SignUp>>({
  initialValue,
  onSubmit,
  goBack,
  skip,
  onChange,
  children,
  finalStep,
  form,
}: React.PropsWithChildren<Props<T>>) {
  const loading = useSelector(
    (s) =>
      loadable.isLoading(s.account.signUpResult) ||
      loadable.isLoading(s.account.createOrganizationResult)
  )

  return (
    <Form<T>
      initialValues={initialValue}
      onFinish={onSubmit}
      onValuesChange={(_, v) => onChange(v)}
      form={form}
      layout="vertical"
    >
      {children}
      <Form.Item style={{ marginTop: 32 }}>
        <ButtonBar>
          {!!goBack && (
            <Button loading={loading} onClick={goBack}>
              <TranslatedMessage id="back" />
            </Button>
          )}
          <Button loading={loading} htmlType="submit" type="primary">
            {finalStep ? (
              <TranslatedMessage id="signUp" />
            ) : (
              <TranslatedMessage id="next" />
            )}
          </Button>
          {!!skip && (
            <Button loading={loading} onClick={skip}>
              <TranslatedMessage id="skip" />
            </Button>
          )}
        </ButtonBar>
      </Form.Item>
    </Form>
  )
}

export default SignUpStep
