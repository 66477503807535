import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { OrganizationUser } from '@library/react-toolkit'
import { Button, Modal } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { State } from '../../../../shared/state/store'
import { translatedMessage } from '../../../../shared/translations/data'
import { deleteOrganizationUser } from '../../types'

type OwnProps = { user: OrganizationUser }

const UserDeleteModal = ({ user }: OwnProps) => {
  const messageReader = useSelector((s: State) => translatedMessage(s.locale))
  const dispatch = useDispatch()
  const error = () => {
    Modal.confirm({
      title: `Remove User ${user.login}`,
      content: (
        <>
          {messageReader('SelectUserModalText')}
          <br />
          {messageReader('SelectUserModalText2')}
        </>
      ),
      icon: <CloseCircleOutlined style={{ color: 'red' }} />,

      okText: messageReader('confirmation'),
      okType: 'danger',
      cancelText: messageReader('cancel'),
      onOk() {
        dispatch(deleteOrganizationUser(user.login))
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  return <Button danger icon={<DeleteOutlined />} onClick={error} />
}

export default UserDeleteModal
