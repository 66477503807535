import * as axios from './../../../axios'
import { Units, unitToUnitInfo } from './types'
import { pipe } from 'fp-ts/lib/function'
import { record, taskEither } from 'fp-ts'

export const getUnits = (cancelToken?: axios.CancelToken) =>
  axios.get('/dcn/dcn1.0/asset_types', { decoder: Units, cancelToken })

export const getUnitsInfo = (cancelToken?: axios.CancelToken) =>
  pipe(getUnits(cancelToken), taskEither.map(record.map(unitToUnitInfo)))
