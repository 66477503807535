import { useAccount, useOrganization } from '@library/react-toolkit'
import { Card, Result, Tabs } from 'antd'
import { push } from 'connected-react-router'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Switch, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { TranslatedMessage } from '../../../shared/translations/data'
import BackHome from '../../../shared/widgets/BackHome'
import { getWalletsAction } from '../types'
import OrganizationInformation from './OrganizationInformation'
import OrganizationModules from './OrganizationModules'
import UserManagement from './UserManagement'

const CenteredTabs = styled(Tabs)<{ activeKey?: string }>`
  & .ant-tabs-content-holder {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  & .ant-tabs-content {
    ${(props) =>
      props.activeKey !== 'organization-modules'
        ? css`
            max-width: 1280px;
          `
        : null}
  }
`

const OrganizationSetting = () => {
  const organization = useOrganization()
  const dispatch = useDispatch()
  const account = useAccount()
  const hasPermission =
    !!account &&
    !!organization &&
    !!(account.organizations[organization.name]?.authority === 'ADMIN')
  const { tab } = useParams<{ tab?: string }>()

  React.useEffect(() => {
    if (hasPermission) {
      dispatch(getWalletsAction({}))
    }
  }, [hasPermission])

  return (
    <div>
      <BackHome />
      <Card
        loading={!account || !organization}
        title={<TranslatedMessage id="companySettings" />}
      >
        {hasPermission ? (
          <Switch>
            <CenteredTabs
              onChange={(tabKey) =>
                dispatch(push(`/organization_setting/${tabKey}`))
              }
              defaultActiveKey={tab ?? 'organization-info'}
              activeKey={tab ?? 'organization-info'}
            >
              <Tabs.TabPane
                tab={<TranslatedMessage id="organizationInfo" />}
                key="organization-info"
              >
                <OrganizationInformation />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<TranslatedMessage id="userInfo" />}
                key="user-info"
              >
                <UserManagement />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<TranslatedMessage id="organizationModule" />}
                key="organization-modules"
              >
                <OrganizationModules />
              </Tabs.TabPane>
            </CenteredTabs>
          </Switch>
        ) : (
          <Result
            status="error"
            title={<TranslatedMessage id="notAnAdminDescription" />}
          />
        )}
      </Card>
    </div>
  )
}

export default OrganizationSetting
