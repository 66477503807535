import { Button, Card, Typography } from 'antd'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { TranslatedMessage } from '../../../shared/translations/data'
import { useSignUpMode } from './hooks'
import { loadable, useAccount } from '@library/react-toolkit'
import styled from 'styled-components'
import SamonOrganizationSignUp from './samoon/SamonOrganizationSignUp'
import { useSelector } from 'react-redux'

const SignUpCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 500px;
  min-height: 500px;
  & > .ant-card-head-title {
    color: '#eb2f96';
  }
`
const SignUpHeader = styled(Typography.Title)`
  text-align: center;
  margin-bottom: 24px;
`
const SignUp = () => {
  const signUpMode = useSignUpMode()
  const account = useAccount()
  const success = useSelector((s) => loadable.isOk(s.account.signUpResult))
  const [result, setResult] = React.useState(false)
  React.useEffect(() => {
    if (success) {
      setResult(true)
    }
  }, [success])
  return (
    <SignUpCard
      bordered={false}
      title={
        !result ? (
          <SignUpHeader level={3}>
            {' '}
            {signUpMode.type === 'organization' ? (
              <TranslatedMessage id="OrganizationSignUp" />
            ) : (
              <TranslatedMessage id="BankSignUp" />
            )}
          </SignUpHeader>
        ) : null
      }
    >
      {signUpMode.type === 'organization' ? (
        <SamonOrganizationSignUp
          smartHubType="SELLER"
          modules={signUpMode.modules}
        />
      ) : signUpMode.type === 'bank' ? (
        <SamonOrganizationSignUp
          smartHubType="FINANCER"
          modules={signUpMode.modules}
        />
      ) : null}
      {!account && !result && (
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          <TranslatedMessage id="AlreadyHaveAnAccount" />
          <Button type="link" color="primary">
            <Link to="/auth/login">
              <TranslatedMessage id="login" />
            </Link>
          </Button>
        </Typography.Paragraph>
      )}
    </SignUpCard>
  )
}

export default SignUp
