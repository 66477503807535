import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TranslatedMessage } from '../../../../../shared/translations/data'
import { BalanceTrigger, deleteBalanceTrigger } from '../../../types'

const DeleteNotification = ({ trigger }: { trigger: BalanceTrigger }) => {
  const dispatch = useDispatch()
  const result = useSelector(
    (s) => s.accountSetting.deleteBalanceTriggerResult._tag
  )

  const [modal, contextHolder] = Modal.useModal()
  const [confirmModal, setConfirmModal] = React.useState<
    ReturnType<typeof modal.confirm> | undefined
  >(undefined)
  const [state, setState] = React.useState<typeof result>('Loading')

  React.useEffect(() => {
    if (state === 'Loading' && result === 'Ok') {
      confirmModal?.destroy?.()
      setState('NotRequested')
    } else {
      setState(result)
    }
  }, [result, state])

  return (
    <>
      <Button
        danger
        icon={<DeleteOutlined />}
        onClick={() => {
          const m = modal.confirm({
            title: (
              <TranslatedMessage id="deleteNotification" values={trigger} />
            ),
            icon: <CloseCircleOutlined style={{ color: 'red' }} />,
            okText: <TranslatedMessage id="confirm" />,
            okType: 'danger',
            cancelText: <TranslatedMessage id="cancel" />,
            onOk: () => dispatch(deleteBalanceTrigger(trigger.uuid)),
            okButtonProps: { loading: result === 'Loading' },
          })
          setConfirmModal(m)
        }}
      />
      {contextHolder}
    </>
  )
}

export default DeleteNotification
