{
  "walletPermissions": "Wallet permissions",
  "add": "Add",
  "maxLengthExceeded": "Maximum length exceeded",
  "noPermissions": "No permissions",
  "confirm": "Confirm",
  "security": "Account security",
  "services": "Modules",
  "authorities": "Authorities",
  "account": "My account",
  "tfa": "Two factor authentication",
  "tfacode": "Two factor authentication code",
  "login": "Login",
  "logout": "Log out",
  "signUpPersonal": "Sign Up for a personal account",
  "signUpOrganization": "Sign Up for an account with a new organization",
  "signUpEither": "Sign Up for either",
  "signUp": "Sign Up",
  "password": "Password",
  "verifyPassword": "Verify password",
  "changePassword": "Change password",
  "currentPassword": "Current password",
  "newPassword": "New password",
  "confirmPassword": "Confirm password",
  "username": "Username",
  "requiredJobTitle": "Job Title is required",
  "requiredPassword": "Enter a valid password",
  "invalidPassword": "Password must be at least 8 characters long",
  "mismatchedPasswords": "Passwords do not match",
  "requiredUsername": "Enter a valid username",
  "requiredEmail": "Must enter e-mail address",
  "requiredCode": "Must enter two factor authentication code",
  "invalidEmail": "Invalid e-mail address",
  "loginFailed": "Login failed, check your username and password or click on Signup to create new account",
  "signupFailed": "Signup failed",
  "signUpSuccessTitle": "CONGRATULATION!",
  "signUpSuccessDescription": "Your sign up has been registered successfully, please check your email to activate your account.",
  "en": "English",
  "fr": "French",
  "forgotPassword": "Forgot password?",
  "lostTFACode": "Lost your TFA code?",
  "resendTFACode": "Resend two factor authentication code",
  "resend": "Resend",
  "resetPassword": "Reset Password",
  "genratorPassword": "You can use this password",
  "next": "Next",
  "back": "Back",
  "setPasswordDescription": "To complete the registration process, please set your password so that you can login",
  "addPassword": "Adding a password",
  "changeUsername": "you can change your username",
  "changeRequestFailed": "Change password request failed",
  "changeRequestSuccess": "Change password request successful",
  "resetRequestFailed": "Reset request failed, check e-mail address",
  "resetRequestSuccess": "Reset request successful, check your inbox",
  "tfaRequestFailed": "Request failed",
  "tfaRequestSuccess": "Request send successfully",
  "finishResetFailed": "Password reset failed",
  "finishResetSuccess": "Password reset successfully",
  "finishSetFailed": "Password set failed",
  "finishSetSuccess": "Password set successfully",
  "email": "E-mail address",
  "cancel": "Cancel",
  "notAnAdmin": "You are not an administrator",
  "notAnAdminDescription": "You must be an administrator to view or modify organization settings.",
  "notificationsAndAlerts": "Notifications and Alerts",
  "backToApplicationSelect": "Back to application select",

  "ROLE_USER": "User",
  "ROLE_ADMIN": "Admin",
  "ROLE_SUPER_ADMIN": "Super Admin",

  "organizationSettings": "Organization Settings",
  "googleSignup": "Google signup",
  "enabled": "Enabled",
  "disabled": "Disabled",
  "enable": "Enable",
  "disable": "Disable",
  "requiredFirstName": "Must enter firstName",
  "requiredLastName": "Must enter lastName",
  "firstName": "First Name",
  "lastName": "Last Name",
  "fullName": "Full Name",
  "createAccountPrompt": "Do you want to create new account",
  "userexists": "This login is already in use!",
  "nextDescription": "Enter your email and click next to continue the  process of sign up",
  "setUserInfo": "User information",
  "setPassword": "Password",
  "setOrganization": "Organization Information",
  "setWallet": "Wallet Initialization",
  "done": "Done",
  "skip": "Skip",
  "emailexists": "This Email is already in use!",
  "personalSetting": "Personal settings",
  "companySettings": "Organization Settings",
  "modules": "Modules",
  "organizationInfo": "Organization Information",
  "userInfo": "Organization Users",
  "activity": "Activity",
  "entertaiment": "Entertainment",
  "organizationEmployees": "Number of employees",
  "organizationName": "Organization Name",
  "employees": "Employees",
  "employee": "Employee",
  "bank": "Bank",
  "googleLogin": "Login with Google",
  "createAccountQuestion": "Don't have an account?",
  "personnalInformation": "Personal Information",
  "SecurityInformation": "Security Information",
  "picture": "Picture",
  "selectPicture": "Select Picture",
  "upload": "Upload",
  "emailLabel": "Email",
  "organizationLabel": "Organizations",
  "jobTitle": "Job Title",
  "language": "Language",
  "save": "Save",
  "missingUsernameMsg": "Please input your username!",
  "missingEmailMsg": "Please input your email!",
  "missingFirstnameMsg": "Please input your First Name!",
  "missingLastnameMsg": "Please input your Last Name!",
  "missingJobTitleMsg": "Please input your job Title!",
  "enableTwoFactor": "Enable Two Factor Authentication",
  "twoFactor": "Two factor authentication",
  "prosperUs_Url": "ProsperUs_Url",
  "asset": "Asset",
  "country": "country",
  "Fiscal_Year": "Fiscal Year",
  "fiscal_ID": " Fiscal Id",
  "Financial_Sector": "Financial Sector",
  "organizationModule": "Organization Modules",
  "setUser": "User information",
  "confirmation": "Confirm",
  "AddUser": "Add User",
  "AddOrganizationUser": "Add Organization User",
  "EditOrganizationUser": "Edit User {login}",
  "Permissions": "Permissions",
  "Contributor": " Contributor",
  "Viewer": "Viewer",
  "None": "None",
  "SelectUserModalText": "Are you sure you want to remove this user",
  "SelectUserModalText2": "All records associated with this user will be lost",
  "missingCompanyNameMsg": "Please input your Organization Name!",
  "missingCRNumberMsg": "Please input your CR Number",
  "missingBankMsg": "Please input your Bank Name!",
  "invalidRIB": "Invalid RIB Length",
  "invalidRIBFormat": "Invalid RIB Format",
  "missingRIBMsg": "Please input your RIB!",
  "RIB": "RIB",
  "missingFullNameMsg": "Please input your Full Name!",
  "IBAN": "IBAN",
  "OrganizationUrl": "Organization Url",
  "OrganizationNameExist": "Organization Name Exist",
  "Next": "Next",
  "isVerifEmail": "The User Email is already used, If you press submit it will be associated to the new organization",
  "isVerifyUserName": "The User Name is already used",
  "Previous": "Previous",
  "Submit": "Submit",
  "OrganizationSignUp": "Organization Sign-Up",
  "SLOGEN": " The Enabler of Digital Transactions.",
  "AlreadyHaveAnAccount": "Already have an account?",
  "noAuthority": "No Authority",
  "NOAUTHORG": "You are not authorized in this page, please contact your organization admin",
  "noModulePermissions": "Your Account doesn't have the permission to access any module please contact admin",
  "Loading": "Loading",
  "TermsOfUse": "Terms of Use",
  "Compliance": "Compliance",
  "Support": "Support",
  "Contact": "Contact",
  "CopyRightMessage": "Copyright © 2017-2022 Prosperus | Privacy Policy",
  "EmailOrUserName": "Email or UserName",
  "ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_SUCCESS": "The user has being edited successfully",
  "ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_ERROR": "Editing the user has failed",
  "ACCOUNT/POST_ORGANIZATION_USERS_RESULT_SUCCESS": "The user has being added successfully",
  "ACCOUNT/POST_ORGANIZATION_USERS_RESULT_ERROR": "Adding the user has failed",
  "ACCOUNT/login_result_RESULT_ERROR": " Login failed",
  "ACCOUNT/login_result_RESULT_SUCCESS": "Login successful",
  "ACCOUNT/update_result_RESULT_SUCCESS": "User information is updated successfully",
  "ACCOUNT/update_result_RESULT_ERROR": "User update has Failed",
  "ACCOUNT/update_organization_result_RESULT_SUCCESS": "Organization information has been updated successfuly",
  "ACCOUNT/update_organization_result_RESULT_ERROR": "Organization information update has failed",
  "ACCOUNT/get_balance_trigger_result_error": "Could not get balance notifications",
  "ACCOUNT/add_balance_trigger_result_error": "Could not add balance notification",
  "ACCOUNT/add_balance_trigger_result_success": "Balance notification added successfully",
  "ACCOUNT/update_balance_trigger_result_error": "Could not update balance notification",
  "ACCOUNT/update_balance_trigger_result_success": "Balance notification updated successfully",
  "ACCOUNT/delete_balance_trigger_result_error": "Could not delete balance notification",
  "ACCOUNT/delete_balance_trigger_result_success": "Balance notification deleted successfully",

  "ACCOUNT/add_phonenumber_result_error": "Could not add phonenumber",
  "ACCOUNT/add_phonenumber_result_success": "Phonenumber added successfully",
  "ACCOUNT/verify_phonenumber_result_error": "Could not verify phonenumber",
  "ACCOUNT/verify_phonenumber_result_success": "Phonenumber verified successfully",

  "BACKOFFICE": "BackOffice",
  "PROSPERBILL": "Bills",
  "BILLS_ADMIN": "Smart Hub Admin",
  "BILLS_ADMIN-description": "Monitor Smart Hub receivables",
  "DASHBOARD": "Dashboard",
  "MARKETPLACE": "MarketPlace",
  "WEB_WALLET": "Wallets",
  "PersonalAccount": "Personal Account",
  "Services": "Services",
  "Public_Sector": "Public Sector",
  "NgoNonProfit": "Ngo Non Profit",
  "Agriculture": "Agriculture",
  "Entertainment": "Entertainment",
  "Information-Technologies": "Information Technologies",
  "Consumer Goods": "Consumer Goods",
  "Less-then-5": "Less then 5",
  "Between-5-and-10": "Between 5 and 10",
  "Between-10-and-20": "Between 10 and 20",
  "Between-20-and-50": "Between 20 and 50",
  "Between-50-and-100": "Between 50 and 100",
  "Greater-then-100": "Greater-then-100",
  "defaultUnitRequired": "Default Unit Required",
  "accept": "Accept",
  "addUserExists": "User {login} with email {email} already exists, would you like to add them?",
  "withWallet": "Initialize a wallet",
  "addingExistingUser": "Adding user {login}.",

  "activationKeyInvalidTitle": "Invalid activation key",
  "activationKeyInvalidSubtitle": "Please check the activation link and try again",

  "activationSuccessfulTitle": "Your account has been activated",
  "activationSuccessfulSubtitle": "You will be redirected to the login page shortly",

  "backToLogin": "Back to login",
  "invalidResetKeyTitle": "Invalid reset key",
  "invalidResetKeySubTitle": "Please check your reset link and try again.",

  "personalAccount": "Personal account",
  "CONTRIBUTOR": "Contributor",
  "NONE": "None",
  "ADMIN": "Admin",
  "USER": "User",

  "invalidLogin": "Login can only use alphanumeric characters or '@', '.', '-' and '_'",
  "signUpAlreadyLoggedIn": "You are already logged in as ''{login}'', do you want to proceed?",
  "goToHomePage": "Go to homepage",

  "organizationCreatedDescription": "Your organization account was successfully created. A confirmation email was sent to you. Please click on the link to verify your email address.",
  "personalSettings": "Personal settings",
  "pageNotFound": "The page you're looking for doesn't exist",
  "workspaces": "Workspaces",
  "pWorkspace": "Personal Workspace",
  "orgWorkspaceSuffix": "Workspace",
  "organizationAccount": "{displayName} account",
  "noWorkspaceOptions": "No workspace options",
  "onlyAlphanumeric": "Only alphanumeric characters are allowed",

  "deleteNotification": "Delete notification ''{name}''",
  "name": "Name",
  "module": "Module",
  "type": "Type",
  "actions": "Actions",
  "sms": "SMS",
  "emailOption": "Email",
  "channel": "Channel",
  "wallet": "Wallet",
  "minBalance": "Low Balance",
  "maxBalance": "High Balance",
  "threshold": "Threshold",
  "requiredName": "Must enter name",
  "requiredType": "Must select type",
  "requiredWallet": "Must select wallet",
  "addNotification": "Add Notification",
  "addPhonenumber": "Add",
  "updateNotification": "Update Notification ''{name}''",
  "newNotification": "New Notification",
  "editNotification": "Edit Notification",
  "phonenumberLabel": "Phonenumber",
  "verificationCode": "Verification code",
  "newPhonenumber": "Add a Phone Number",
  "edit": "Edit",
  "phonenumberRequired": "Missing phonenumber field",
  "smsSentMessage": "An SMS with a verification code has been sent to you.",
  "smsCodePrompt": "Please enter the code below.",
  "resendSMSCodeCountdown": "You can resend a verfication in {countdown} seconds",
  "resendSMSCodePrompt": "Didn't receive an SMS?",

  "AMSettings_Validators": "Validators",
  "AMSettings_Modal_Title": "Number of Operation Validators",
  "AMSettings_Num_Contributors": "Total N° of Validators",
  "AMSettings_Min_Required": "Minimum N° of Validators",
  "AMSettings_Contributor_Placeholder": "Select Validator",
  "AMAPPROVAL/upsert_config_result_error": "Could not update config",

  "8Characters": "8 Characters",
  "oneCapitalLetter": "At least 1 capital letter",
  "oneDigit": "At least 1 digit",
  "oneSpecailCharacter": "At least 1 special character",

  "nafadhTitle": "Nafadh Authentication",
  "resendEmail": "Resend email",
  "signUpBank" :" Sign Up for Bank",
  "BankSignUp":"Bank Sign Up",
  "crNumber": "CR number"

}
