import React, { useState } from 'react'
import { Alert, Button, Divider, Result, Steps } from 'antd'
import { TranslatedMessage } from '../../../../shared/translations/data'
import { useDispatch, useSelector } from 'react-redux'
import { signUpAction, SignUp as SignUpData } from '../../Login'
import { loadable, Module, Organization } from '@library/react-toolkit'
import OrganizationInfoStep from './OrganizationInfoStep'
import UserInfoSignUp from './UserInfoSignUp'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { push } from 'connected-react-router'
import { useSignUpMode } from '../hooks'

const ResultComponent = styled(Result)`
  width: 500px;
  text-align: center;
  & > .ant-result-title {
    color: ${(props) => props.theme.colors.primary};
  }
`
type Step = 0 | 1 | 2

const nextStep: Record<Step, Step> = { 0: 1, 1: 2, 2: 2 }
const prevStep: Record<Step, Step> = { 0: 0, 1: 0, 2: 1 }

const incrementStep = (s: Step): Step => nextStep[s]
const decrementStep = (s: Step): Step => prevStep[s]
type OrganizationData = Pick<
  Organization & { crNumber: string },
  'name' | 'displayName' | 'rib' | 'asset' | 'modules' | 'crNumber'
>

type FormData = {
  organization: OrganizationData
} & Omit<SignUpData, 'organization'>

const SamonOrganizationSignUp = ({
  modules,
  smartHubType,
}: {
  modules: Module[]
  smartHubType: string
}) => {
  const dispatch = useDispatch()
  const locale = useSelector((s) => s.locale)
  const failed = useSelector((s) => loadable.isErr(s.account.signUpResult))
  const success = useSelector((s) => loadable.isOk(s.account.signUpResult))
  const signUpMode = useSignUpMode()
  const loading = useSelector((s) =>
    loadable.isLoading(s.account.createOrganizationResult)
  )
  const [result, setResult] = useState(false)

  const [step, setStep] = React.useState<Step>(0)
  const [user, setUser] = React.useState<FormData>({
    email: '',
    login: '',
    firstName: '',
    lastName: '',
    password: '',
    organization: {
      name: '',
      displayName: '',
      rib: '',
      crNumber: '',
      asset: '',
      modules,
    },
    withWallet: false,
    smartHubType: smartHubType,
    appName: 'samoon',
  })

  React.useEffect(() => {
    setUser((u) => ({ ...u, organization: { ...u.organization, modules } }))
  }, [modules])

  React.useEffect(() => {
    if (success) {
      setStep(2)
      setResult(true)
    }
  }, [success])

  const onSubmit = (v: Partial<FormData>) => {
    setUser((u) => ({ ...u, ...v }))
    if (step === 1) {
      dispatch(
        signUpAction({
          ...user,
          firstName: user.organization.name,
          lastName: user.organization.name,
          organization: {
            ...user.organization,
            displayName: user.organization.name,
            asset: '8iq3uYdq3yJ4FBuCDAkvqVvDVdyozipr1pguHUy7dYZJ',
            rib: '12345678911234567891',
            crNumber: user.organization.crNumber,
            modules:
              signUpMode.type === 'bank'
                ? ['SMART_HUB']
                : ['SMART_HUB', 'PROSPERBILL'],
          },
          ...v,
        })
      )
    } else {
      setStep(incrementStep)
    }
  }
  const goBack = () => setStep(decrementStep)

  const onChange = (v: Partial<FormData>) => setUser((u) => ({ ...u, ...v }))

  const stepProps = { onSubmit, onChange, locale, initialValue: user }
  return result ? (
    <ResultComponent
      status="success"
      icon={<></>}
      title={<TranslatedMessage id="signUpSuccessTitle" />}
      subTitle={<TranslatedMessage id="organizationCreatedDescription" />}
      extra={
        <Button
          loading={loading}
          onClick={() => dispatch(push('/auth/activate'))}
          type="primary"
        >
          <Link to="/">
            <FormattedMessage id="resendEmail" />
          </Link>
        </Button>
      }
    />
  ) : (
    <>
      <Steps current={step} size="small" style={{ color: 'green' }}>
        {signUpMode.type === 'organization' ? (
          <Steps.Step title={<TranslatedMessage id="setOrganization" />} />
        ) : (
          <Steps.Step title={<TranslatedMessage id="setOrganization" />} />
        )}

        <Steps.Step title={<TranslatedMessage id="setUserInfo" />} />
      </Steps>
      <Divider />
      {step === 0 ? (
        <OrganizationInfoStep {...stepProps} />
      ) : step === 1 ? (
        <UserInfoSignUp {...stepProps} goBack={goBack} finalStep={true} />
      ) : null}
      {failed && (
        <Alert message={<TranslatedMessage id="signupFailed" />} type="error" />
      )}
    </>
  )
}

export default SamonOrganizationSignUp
