import {
  Col,
  FormInstance,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tag,
} from 'antd'
import { option } from 'fp-ts'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { State } from '../../../../shared/state/store'
import { TranslatedMessage } from '../../../../shared/translations/data'
import { loadable } from '../../../../shared/types'

const preventMouseDown: React.MouseEventHandler<HTMLSpanElement> = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

type OwnProps = {
  form: FormInstance<any>
  walletPermissions?: {
    nym: string
    permission: string
  }[]
}

const SelectWalletPermission = ({ form, walletPermissions }: OwnProps) => {
  const [permissions, setPermissions] = React.useState<
    { nym: string; permission: string }[]
  >([])

  const [nyms, setNyms] = React.useState<string[]>([])

  const wallets = useSelector((s: State) =>
    option.toUndefined(loadable.toOption(s.accountSetting.wallets))
  )

  React.useEffect(() => {
    walletPermissions && setPermissions(walletPermissions)
    walletPermissions && setNyms(walletPermissions.map((x) => x.nym))
  }, [walletPermissions])

  return (
    <Select
      mode="multiple"
      tagRender={({ value, closable, onClose }) => (
        <Tag
          onMouseDown={preventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {wallets?.data?.find((w) => w.nym === value)?.firstName}
          {wallets?.data?.find((w) => w.nym === value)?.lastName}
        </Tag>
      )}
      value={nyms}
      onChange={() => {}}
    >
      {wallets?.data?.map((w) => (
        <Select.Option key={w.nym} value={w.nym}>
          <Row justify="space-around" align="middle">
            <Col span={8}>
              {w?.firstName} {w?.lastName}
            </Col>
            <Col span={8}>
              <Radio.Group
                value={permissions.find((x) => x.nym === w.nym)?.permission}
                onChange={(e: RadioChangeEvent) => {
                  if (e.target.value === 'NONE') {
                    console.log(nyms)
                    const filteredNyms = nyms.filter((n) => n !== w.nym)
                    setNyms(filteredNyms)
                    const filteredPermissions = walletPermissions?.filter(
                      (p) => p.nym !== w.nym
                    )
                    setPermissions(filteredPermissions ?? [])
                    const values = form.getFieldsValue(true)
                    form.setFieldsValue({
                      ...values,
                      walletPermissions: filteredPermissions,
                    })
                  } else {
                    setNyms(Array.from(new Set([...nyms, w.nym])))

                    const updatePermission = permissions.map((x) => {
                      return x.nym === w.nym
                        ? { nym: w.nym, permission: e.target.value }
                        : x
                    })
                    const newPermissions = permissions.find(
                      (x) => x.nym === w.nym
                    )
                      ? [...updatePermission]
                      : [
                          ...permissions,
                          { nym: w.nym, permission: e.target.value },
                        ]

                    setPermissions(newPermissions)
                    const values = form.getFieldsValue(true)
                    form.setFieldsValue({
                      ...values,
                      walletPermissions: newPermissions,
                    })
                  }
                }}
              >
                <Radio.Button value="CONTRIBUTOR">
                  <TranslatedMessage id="Contributor" />
                </Radio.Button>
                <Radio.Button value="VIEWER">
                  <TranslatedMessage id="Viewer" />
                </Radio.Button>
                <Radio.Button value="NONE">
                  <TranslatedMessage id="None" />
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Select.Option>
      ))}
    </Select>
  )
}

export default SelectWalletPermission
