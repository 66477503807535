import * as axios from '../../../../../axios'
import * as t from 'io-ts'
import {
  loadable,
  Loadable,
  Loading,
  memoed,
  Ok,
  UAAAccount,
  useAccount,
} from '@library/react-toolkit'
import { Form, Input, InputNumber, Radio, Select, Spin, Tooltip } from 'antd'
import { array, either, record, task, taskEither } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { State } from '../../../../../shared/state/store'
import { TranslatedMessage } from '../../../../../shared/translations/data'
import { optionFromNullable } from 'io-ts-types'

const HiddenItem = styled(Form.Item)`
  display: none;
`

const Step1 = ({ disableModule = false }) => (
  <>
    <Form.Item
      name="name"
      label={<TranslatedMessage id="name" />}
      rules={[
        {
          required: true,
          message: <TranslatedMessage id="requiredName" />,
        },
      ]}
    >
      <Input allowClear />
    </Form.Item>
    <Form.Item name="module" label={<TranslatedMessage id="module" />}>
      <Select disabled={disableModule}>
        <Select.Option value="WEB_WALLET">
          <TranslatedMessage id="WEB_WALLET" />
        </Select.Option>
      </Select>
    </Form.Item>
  </>
)

const getWallets = (account: UAAAccount & { wallets: string[] }) => {
  const ws = [...account.wallets]

  for (const k in account.organizations) {
    ws.push(...Object.keys(account.organizations[k].walletPermissions))
  }

  return ws
}

export const getWalletName = (nym: string) =>
  pipe(
    axios.get(`/dis/dis/1.0/profile/${nym}`, {
      headers: { 'user-nym': nym },
      decoder: t.type({
        firstName: optionFromNullable(t.string),
        lastName: optionFromNullable(t.string),
      }),
    }),
    taskEither.map((p) => {
      const n = [p.firstName, p.lastName]
      const name = array.compact(n).join(' ')
      if (!!name) {
        return name
      } else {
        return nym
      }
    }),
    task.map(
      either.fold(
        (e) => {
          console.error(`could not get profile for ${nym}`, e)
          return nym
        },
        (x) => x
      )
    )
  )

const FormItemHalf = styled(Form.Item)`
  display: inline-block;
  width: calc(50% - 4px);
  & .ant-input-number {
    width: 100%;
  }
`

const Step2 = () => {
  const units = useSelector((s: State) => memoed.extract(s.units))
  const account = useAccount()
  // TODO: the UAAAccount type should be fixed in react-toolkit
  // @ts-ignore
  const wallets = !!account ? getWallets(account) : []

  const [walletNames, setWalletNames] = React.useState<
    Record<string, Loadable<string>>
  >({})

  React.useEffect(() => {
    task.sequenceSeqArray(
      wallets.map((nym) => {
        const prevState = walletNames[nym]
        if (
          !!prevState &&
          (loadable.isLoading(prevState) || loadable.isOk(prevState))
        ) {
          return task.of(undefined)
        }

        setWalletNames((ws) => ({ ...ws, [nym]: Loading }))

        return pipe(
          getWalletName(nym),
          task.map((name) =>
            setWalletNames((ws) => ({ ...ws, [nym]: Ok(name) }))
          )
        )
      })
    )()
  }, [wallets])

  const WalletWithName = ({ nym }: { nym: string }) => {
    const lName = walletNames[nym]
    const loading = !lName || loadable.isLoading(lName)
    return (
      <Spin spinning={loading}>
        <Tooltip overlay={nym}>
          {!!lName && loadable.isOk(lName) ? lName.value : nym}
        </Tooltip>
      </Spin>
    )
  }

  return (
    <>
      <HiddenItem name="name" />
      <HiddenItem name="module" />
      <Form.Item
        label={<TranslatedMessage id="wallet" />}
        name="nym"
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="requiredWallet" />,
          },
        ]}
      >
        <Select>
          {wallets.map((nym) => (
            <Select.Option key={nym} value={nym}>
              <WalletWithName nym={nym} />
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={<TranslatedMessage id="type" />}
        name="type"
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="requiredType" />,
          },
        ]}
      >
        <Select allowClear>
          <Select.Option value="Max">
            <TranslatedMessage id="maxBalance" />
          </Select.Option>
          <Select.Option value="Min">
            <TranslatedMessage id="minBalance" />
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label={<TranslatedMessage id="threshold" />}>
        {/* TODO: use amount input */}
        <FormItemHalf style={{ marginRight: '8px' }} name="amount">
          <InputNumber min={1} max={100000} />
        </FormItemHalf>
        <FormItemHalf name="asset">
          <Select>
            {pipe(
              units,
              record.map((u) => `${u.name} ${u.symbol}`),
              record.toArray,
              array.map((v) => (
                <Select.Option value={v[0]} key={v[0]}>
                  {v[1]}
                </Select.Option>
              ))
            )}
          </Select>
        </FormItemHalf>
      </Form.Item>
      <Form.Item label={<TranslatedMessage id="channel" />} name="channel">
        <Radio.Group>
          <Radio value="Email">
            <TranslatedMessage id="emailOption" />
          </Radio>
          <Radio value="SMS">
            <TranslatedMessage id="sms" />
          </Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export { Step1, Step2, HiddenItem }
