import { Header } from '@library/react-toolkit'
import { Avatar, Button, Col, Layout, Menu, Result, Row } from 'antd'
import * as React from 'react'
import { Route, Switch } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import AccountApps, {
  useIsSmartHubAdmin,
} from '../../components/Account/AccountApps'
import OrganizationSetting from '../../components/Account/organisationSettings/OrganizationSetting'
import PersonalSetting from '../../components/Account/personalSettings/personalSetting'
import { TranslatedMessage } from '../translations/data'

const Content = styled(Layout.Content)`
  padding: 48px 40px;
`

const MainLayout = () => {
  const isSmartHubAdmin = useIsSmartHubAdmin()
  return (
    <Layout>
      <Header
        logo="Accounts"
        goToPersonalSettings={() =>
          (window.location.href = '/samoon-accounts/personal_setting')
        }
        goToOrganizationSettings={() =>
          (window.location.href = '/samoon-accounts/organization_setting')
        }
        extraModules={
          <>
            {isSmartHubAdmin ? (
              <Menu.Item
                key="bills-admin-app"
                onClick={() => {
                  window.location.href = '/bills-admin-app'
                }}
              >
                <Row style={{ width: '200px' }} align="middle">
                  <Col flex={2}>
                    <Avatar
                      icon={
                        <span className="anticon">
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.35813 1.81874C3.49981 0.805853 4.97377 0.247588 6.5 0.249992C9.95187 0.249992 12.75 3.04812 12.75 6.49999C12.752 7.77743 12.3608 9.02453 11.6294 10.0719L9.9375 6.49999H11.5C11.5 5.50584 11.2037 4.53425 10.6488 3.70934C10.094 2.88442 9.30581 2.24364 8.38502 1.86884C7.46422 1.49404 6.4526 1.40225 5.47938 1.60518C4.50616 1.80811 3.61552 2.29655 2.92125 3.00812L2.35875 1.81937L2.35813 1.81874ZM10.6419 11.1812C9.50019 12.1941 8.02623 12.7524 6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.49999C0.25 5.17187 0.664375 3.94062 1.37063 2.92812L3.0625 6.49999H1.5C1.49998 7.49415 1.79633 8.46574 2.35117 9.29065C2.90602 10.1156 3.69419 10.7563 4.61498 11.1311C5.53578 11.5059 6.5474 11.5977 7.52062 11.3948C8.49384 11.1919 9.38448 10.7034 10.0787 9.99187L10.6412 11.1806L10.6419 11.1812ZM7.38375 8.26749L5.61625 6.49999L3.84812 8.26749L2.96438 7.38374L5.61625 4.73249L7.38375 6.49999L9.15187 4.73249L10.0356 5.61624L7.38375 8.26749Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      }
                    />
                  </Col>
                  <Col>
                    <a href="/bills-admin-app" />
                    <TranslatedMessage id="BILLS_ADMIN" />
                  </Col>
                </Row>
              </Menu.Item>
            ) : null}
          </>
        }
        filterWorkspaces={() => true}
      />
      <Content>
        <Switch>
          <Route path="/" exact component={AccountApps} />
          <Route path="/personal_setting/:tab?" component={PersonalSetting} />
          <Route
            path="/organization_setting/:tab?"
            component={OrganizationSetting}
          />
          <Route>
            <Result
              status="warning"
              title="404"
              subTitle={<TranslatedMessage id="pageNotFound" />}
              extra={
                <Button type="primary">
                  <Link to="/">
                    <TranslatedMessage id="goToHomePage" />
                  </Link>
                </Button>
              }
            />
          </Route>
        </Switch>
      </Content>
    </Layout>
  )
}

export default MainLayout
