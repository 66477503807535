import {
  CheckCircleOutlined,
  CloseOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Badge, Button, Divider, Form, Input, Typography } from 'antd'
import { RuleObject } from 'antd/lib/form'
import { either } from 'fp-ts'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../../shared/translations/data'
import { verifyUserExists } from '../../Login/api'
import SignUpStep, { Props as StepProps } from '../SignUpStep'
import PasswordChecklist from 'react-password-checklist'

type UserInfo = {
  email: string
  login: string
  password: string
}
const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`
const ThreeColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
const ButtonNafadhAuth = styled.div`
display: flex;
justify-content: center;
align-items: center;
& .ant-btn{
  touch-action:pan-down
}
}
`
const TwoRows = styled.div`
  display: flex;
  flex-direction: column;
`

const checkUsernameValidator = async (_: RuleObject, v: string) => {
  if (either.isRight(await verifyUserExists(v)())) {
    throw 'exists'
  }
}

const checkEmailValidator = async (_: RuleObject, v: string) => {
  if (either.isRight(await verifyUserExists(v)())) {
    throw 'exists'
  }
}

type FormProps<T> = StepProps<T> & { goBack?: () => void }

const passwordIcons = {
  ValidIcon: <Badge status="success" />,
  InvalidIcon: <CloseOutlined style={{ color: 'red' }} />,
}

const UserInfoSignUp = ({ ...stepProps }: FormProps<UserInfo>) => {
  const locale = useSelector((s) => s.locale)
  const [password, setPassword] = useState<string>('')
  const intl = useIntl()

  return (
    <SignUpStep<UserInfo> {...stepProps} goBack={stepProps.goBack}>
      <ButtonNafadhAuth>
        <Button autoFocus type="primary" icon={<CheckCircleOutlined />}>
          {intl.formatMessage({ id: 'nafadhTitle' })}
        </Button>
      </ButtonNafadhAuth>
      <ThreeColumn>
        <Divider />
        <Typography.Text
          style={{
            textAlign: 'center',
            marginTop: 12,
          }}
        >
          <FormattedMessage id="Or" />
        </Typography.Text>
        <Divider />
      </ThreeColumn>
      <TwoColumn>
        <TwoRows>
          <Form.Item
            label={<TranslatedMessage id="email" />}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: <TranslatedMessage id="invalidEmail" />,
              },
              {
                message: <TranslatedMessage id="emailexists" />,
                validator: checkEmailValidator,
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              allowClear
              type="email"
              placeholder={translatedMessage(locale)('email')}
            />
          </Form.Item>
          <Form.Item
            label={<TranslatedMessage id="username" />}
            name="login"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="requiredUsername" />,
              },
              {
                max: 50,
                message: <TranslatedMessage id="maxLengthExceeded" />,
              },
              {
                message: <TranslatedMessage id="userexists" />,
                validator: checkUsernameValidator,
              },
              {
                message: <TranslatedMessage id="invalidLogin" />,
                pattern: /^[_.@A-Za-z0-9-]*$/,
              },
            ]}
          >
            <Input
              allowClear
              prefix={<UserOutlined />}
              placeholder={translatedMessage(locale)('username')}
            />
          </Form.Item>
        </TwoRows>
        <TwoRows>
          <Form.Item
            label={<TranslatedMessage id="password" />}
            name="password"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="requiredPassword" />,
              },
            ]}
          >
            <Input.Password
              onChange={(v) => setPassword(v.target.value)}
              type="password"
              placeholder={translatedMessage(locale)('password')}
            />
          </Form.Item>
          <PasswordChecklist
            rules={['minLength', 'capital', 'number', 'specialChar']}
            minLength={8}
            value={password}
            messages={{
              minLength: intl.formatMessage({ id: '8Characters' }),
              specialChar: intl.formatMessage({ id: 'oneSpecailCharacter' }),
              number: intl.formatMessage({ id: 'oneDigit' }),
              capital: intl.formatMessage({ id: 'oneCapitalLetter' }),
            }}
            iconComponents={passwordIcons}
          />
        </TwoRows>
      </TwoColumn>
    </SignUpStep>
  )
}

export default UserInfoSignUp
