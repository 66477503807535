import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from 'antd'
import { SettingFilled } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { updateOrganizationModulesAction } from '../../../../shared/state/organization'
import { Module, Organization } from '@library/react-toolkit'
import { getUsers } from './api'
import { upsertAMAConfigAction } from './state'
import { putOrganizationUsers, UserPermissions } from '../../types'

const { Title } = Typography

const ModuleSettings = styled(Button)`
  align-self: center;
  grid-column: 3;
  grid-row: 2;
  margin-bottom: 16;
`

export const ModalTitle = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0em;
`
type props = {
  organization: Organization | undefined
  modules: Module[]
}

const AMSettings = ({ organization, modules }: props) => {
  const dispatch = useDispatch()

  const [users, setUsers] = useState<UserPermissions[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [NumContributors, setNumContributors] = useState<number>(1)
  const [MinRequired, setMinRequired] = useState<number>(1)
  const [Contributors, setContributors] = useState<{ [key: number]: string }>(
    {}
  )

  useEffect(() => {
    getUsers(organization!.name, 0, 50, []).then((data: UserPermissions[]) => {
      setUsers(data)
    })
  }, [modules])

  useEffect(() => {
    let c: { [key: number]: string } = {}
    for (let i = 0; i < NumContributors; i++) {
      if (Contributors[i] != undefined) {
        c[i] = Contributors[i]
      }
    }
    setContributors(c)
  }, [NumContributors])

  const [form] = Form.useForm()
  const intl = useIntl()

  const resetFormAndCloseModal = () => {
    form.resetFields()
    setNumContributors(1)
    setMinRequired(1)
    setContributors({})
    setIsModalVisible(false)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    modules.push('ASSET_MANAGEMENT')
    dispatch(updateOrganizationModulesAction({ ...organization!, modules }))
    dispatch(upsertAMAConfigAction({ minimum: MinRequired }))
    for (let i = 0; i < NumContributors; i++) {
      for (let u of users) {
        if (Contributors[i] === u.login) {
          console.log(u.login)
          dispatch(
            putOrganizationUsers({
              authority: u.authority,
              login: u.login,
              modulePermissions: {
                ...u.modulePermissions,
                ASSET_MANAGEMENT: 'CONTRIBUTOR',
              },
              walletPermissions: u.walletPermissions,
              jobTitle:
                u.jobTitle != undefined ? u.jobTitle : 'ASSET MANAGEMENT',
            })
          )
          break
        }
      }
    }
    setIsModalVisible(false)
    resetFormAndCloseModal()
  }

  const column = [
    {
      title: <FormattedMessage id={'AMSettings_Validators'} />,
      dataIndex: 'validator',
      key: 'validator',
    },
  ]
  const data = []

  for (let i = 0; i < NumContributors; i++) {
    data.push({
      key: i,
      validator: (
        <Form.Item
          name={'contributor' + i}
          rules={[{ required: true, message: 'Please select a validator!' }]}
          preserve={false}
        >
          <Select
            showSearch
            placeholder={intl.formatMessage({
              id: 'AMSettings_Contributor_Placeholder',
            })}
            optionFilterProp="label"
            onChange={(value: string) => {
              setContributors((c) => ({ ...c, [i]: value }))
            }}
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {users
              .filter((u) => {
                let f = true
                for (let j = 0; j < NumContributors; j++) {
                  if (Contributors[j] == u.login) {
                    f = false
                  }
                }
                return f
              })
              .map((user) => (
                <Select.Option key={user.login} value={user.login}>
                  {user.login}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      ),
    })
  }

  return (
    <>
      <ModuleSettings
        type="text"
        shape="circle"
        icon={<SettingFilled style={{ fontSize: '25px' }} />}
        size="large"
        onClick={showModal}
      />
      <Modal
        title={
          <Title level={3}>
            <ModalTitle>
              <FormattedMessage id={'AMSettings_Modal_Title'} />
            </ModalTitle>
          </Title>
        }
        width={580}
        visible={isModalVisible}
        onCancel={resetFormAndCloseModal}
        footer={[
          <Button
            key={'cancel'}
            form={'AMSettings'}
            onClick={resetFormAndCloseModal}
          >
            <FormattedMessage id={'cancel'} />
          </Button>,
          <Button key={'submit'} form={'AMSettings'} htmlType="submit">
            <FormattedMessage id={'Submit'} />
          </Button>,
        ]}
      >
        <Form
          name="AMSettings"
          id={'AMSettings'}
          initialValues={{
            total: 1,
            minimum: 1,
          }}
          form={form}
          layout={'vertical'}
          onFinish={handleOk}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={'total'}
                label={<FormattedMessage id={'AMSettings_Num_Contributors'} />}
                rules={[{ required: true }]}
                preserve={false}
              >
                <InputNumber
                  value={NumContributors}
                  min={1}
                  max={users.length}
                  step={1}
                  onChange={(value: number) => setNumContributors(value)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: '0px' }}>
              <Form.Item
                name={'minimum'}
                label={<FormattedMessage id={'AMSettings_Min_Required'} />}
                rules={[{ required: true }]}
                preserve={false}
              >
                <InputNumber
                  value={MinRequired}
                  min={1}
                  max={NumContributors}
                  step={1}
                  onChange={(value: number) => setMinRequired(value)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Table pagination={false} columns={column} dataSource={data} />
        </Form>
      </Modal>
    </>
  )
}

export default AMSettings
