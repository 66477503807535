import { AppstoreOutlined, ArrowRightOutlined } from '@ant-design/icons'
import {
  OrganizationPermission,
  PersonalOrOrg,
  selectOrganizationAction,
  useAvailableWorkspaces,
} from '@library/react-toolkit'
import {
  Avatar,
  Card,
  Col,
  Modal,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TranslatedMessage } from '../../shared/translations/data'

const WorkspaceCard = (
  ws: PersonalOrOrg<OrganizationPermission & { name: string }>
) => {
  const dispatch = useDispatch()

  if (ws.type === 'personal') {
    return <></>
  }

  return (
    <Card
      key={ws.organization.name}
      hoverable
      size="small"
      style={{ textAlign: 'center' }}
      onClick={() =>
        dispatch(
          selectOrganizationAction({
            type: 'organization',
            organization: ws.organization.name,
          })
        )
      }
    >
      <Row align="middle">
        <Col span={6}>
          <Avatar icon={<AppstoreOutlined />} />
        </Col>
        <Col span={12}>
          <Typography.Title
            level={5}
            type="secondary"
            style={{ marginBottom: '0px' }}
          >
            <TranslatedMessage
              id="organizationAccount"
              values={ws.organization}
            />
          </Typography.Title>
        </Col>
        <Col span={6}>
          <ArrowRightOutlined />
        </Col>
      </Row>
    </Card>
  )
}

const SkipOrgSelection = () => {
  const visible = useSelector((s) => s.prosperus.organization.selectModalActive)
  const workspaces = useAvailableWorkspaces()
  const dispatch = useDispatch()

  console.debug(workspaces)

  React.useEffect(() => {
    if (workspaces === 'Loading') return

    for (const ws of workspaces) {
      if (ws.type === 'organization') {
        dispatch(
          selectOrganizationAction({
            type: 'organization',
            organization: ws.organization.name,
          })
        )
        break
      }
    }
  }, [workspaces])

  return (
    <Modal
      width={700}
      centered
      title="ProsperUs Accounts"
      visible={visible}
      closable={false}
      footer={null}
    >
      <Spin spinning={workspaces === 'Loading'}>
        <Row justify="center">
          {workspaces === 'Loading' ? null : (
            <Space style={{ width: '100%' }} direction="vertical">
              {workspaces.length === 0 && (
                <Result
                  status="warning"
                  title={<TranslatedMessage id="noWorkspaceOptions" />}
                />
              )}
              {workspaces.map((ws) => (
                <WorkspaceCard
                  key={ws.type === 'personal' ? ws.type : ws.organization.name}
                  {...ws}
                />
              ))}
            </Space>
          )}
        </Row>
      </Spin>
    </Modal>
  )
}

export default SkipOrgSelection
