import * as axios from '../../../axios'
import * as t from 'io-ts'

export const uploadOrgImage = (org: string, file: FormData) =>
  axios.post(`/uaa/api/organizations/${org}/upload`, file, {
    decoder: t.unknown,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
