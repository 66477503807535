import {
  Alert,
  Button,
  Form,
  FormProps,
  Input,
  Result,
  Row,
  Typography,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import { confirmFinishRequest } from './types'

const ResetForm = styled(
  (p: FormProps<{ newPassword: string; confirmPassword: string }>) => (
    <Form {...p} />
  )
)`
  & > .ant-alert {
    margin-bottom: 16px;
  }
`

const Finish = () => {
  const [form] = useForm<{ newPassword: string; confirmPassword: string }>()
  const location = useLocation()
  const [key, setKey] = React.useState<{ key: string } | 'Pending' | 'Invalid'>(
    'Pending'
  )

  React.useEffect(() => {
    const searchKey = new URLSearchParams(location.search).get('key')
    setKey(!!searchKey && searchKey !== '' ? { key: searchKey } : 'Invalid')
  }, [location.search])

  const locale = useSelector((s: State) => s.locale)

  const loading = useSelector(
    (s: State) =>
      loadable.isLoading(s.prosperus.account) ||
      loadable.isLoading(s.resetPassword.finishRequestResult)
  )
  const error = useSelector((s: State) =>
    pipe(
      s.resetPassword.finishRequestResult,
      loadable.caseOf({ Err: () => true, _: () => false })
    )
  )
  const success = useSelector((s: State) =>
    loadable.isOk(s.resetPassword.finishRequestResult)
  )
  const dispatch = useDispatch()
  return (
    <>
      <Typography.Title level={4}>
        <TranslatedMessage id="resetPassword" />
      </Typography.Title>
      {key === 'Invalid' ? (
        <Result
          status="error"
          title={<FormattedMessage id="invalidResetKeyTitle" />}
          subTitle={<FormattedMessage id="invalidResetKeySubTitle" />}
        />
      ) : (
        <ResetForm
          form={form}
          onFinish={(v) => {
            if (key !== 'Pending') {
              dispatch(
                confirmFinishRequest({
                  key: key.key,
                  newPassword: v.newPassword,
                })
              )
            }
          }}
        >
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="requiredPassword" />,
              },
              { min: 8, message: <TranslatedMessage id="invalidPassword" /> },
            ]}
          >
            {!success && (
              <Input
                placeholder={translatedMessage(locale)('password')}
                disabled={loading || key === 'Pending'}
                type="password"
              />
            )}
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="requiredPassword" />,
              },
              {
                validator: (_, v) =>
                  v !== form.getFieldValue('newPassword')
                    ? Promise.reject('mismatched')
                    : Promise.resolve('ok'),
                message: <TranslatedMessage id="mismatchedPasswords" />,
              },
            ]}
          >
            {!success && (
              <Input
                placeholder={translatedMessage(locale)('confirmPassword')}
                disabled={loading || key === 'Pending'}
                type="password"
              />
            )}
          </Form.Item>
          {error && (
            <Alert
              message={<TranslatedMessage id="finishResetFailed" />}
              type="error"
            />
          )}
          {success && (
            <Alert
              message={<TranslatedMessage id="finishResetSuccess" />}
              type="success"
            />
          )}
          <Row justify="space-between">
            <Button type="primary" htmlType="submit">
              <TranslatedMessage id="resetPassword" />
            </Button>
          </Row>
        </ResetForm>
      )}
    </>
  )
}

export default Finish
