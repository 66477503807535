import { Button, Card, Result } from 'antd'
import { push } from 'connected-react-router'
import { either, task } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { activateAccount } from './Login/api'

type ActivationKey = string | { tag: 'NotSet' } | { tag: 'Pending' }
type ActivationResult = 'Failed' | 'Loading' | 'Ok' | 'NotRequested'

const ActivateCard = styled(Card)`
  min-width: 512px;
  max-width: 512px;
`

const IncorrectKeyOrKeyNotSet = () => (
  <Result
    status="error"
    title={<FormattedMessage id="activationKeyInvalidTitle" />}
    subTitle={<FormattedMessage id="activationKeyInvalidSubtitle" />}
    extra={[
      <Link to="/auth/login">
        <Button type="primary">
          <FormattedMessage id="backToLogin" />
        </Button>
      </Link>,
    ]}
  />
)

const ActivationSuccess = () => (
  <Result
    status="success"
    title={<FormattedMessage id="activationSuccessfulTitle" />}
    subTitle={<FormattedMessage id="activationSuccessfulSubtitle" />}
    extra={[
      <Link to="/auth/login">
        <Button type="primary">
          <FormattedMessage id="backToLogin" />
        </Button>
      </Link>,
    ]}
  />
)

const Activation = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [activationKey, setActivationKey] = React.useState<ActivationKey>({
    tag: 'Pending',
  })
  const [activationResult, setActivationResult] =
    React.useState<ActivationResult>('NotRequested')

  React.useEffect(() => {
    if (!!location?.search) {
      setActivationKey(
        new URLSearchParams(location.search).get('key') ?? { tag: 'NotSet' }
      )
    } else {
      setActivationKey({ tag: 'NotSet' })
    }
  }, [location?.search])

  React.useEffect(() => {
    if (typeof activationKey === 'string') {
      setActivationResult('Loading')
      pipe(
        activateAccount(activationKey),
        task.map(
          either.fold(
            (e) => {
              console.error('activation failed', e)
              setActivationResult('Failed')
            },
            () => setActivationResult('Ok')
          )
        )
      )()
    }
  }, [activationKey])

  React.useEffect(() => {
    if (activationResult === 'Ok') {
      setTimeout(() => dispatch(push('/auth/login')))
    }
  }, [activationResult])

  return (
    <ActivateCard
      loading={
        (typeof activationKey === 'object' &&
          activationKey.tag === 'Pending') ||
        activationResult === 'Loading'
      }
    >
      {(typeof activationKey === 'object' && activationKey.tag === 'NotSet') ||
      activationResult === 'Failed' ? (
        <IncorrectKeyOrKeyNotSet />
      ) : activationResult === 'Ok' ? (
        <ActivationSuccess />
      ) : null}
    </ActivateCard>
  )
}

export default Activation
