import * as t from './types'
import * as api from './api'
import { takeLatest } from '../saga'
import * as effects from 'redux-saga/effects'
import { memoed, Loading } from './../../types'
import { pipe } from 'fp-ts/lib/function'
import { CancelToken } from 'axios'
import { taskEither, record, task } from 'fp-ts'
import { foldEitherToAction } from '@library/react-toolkit'

export const initialState: t.State = memoed.init({})

export const reducer = (s: t.State = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'UNITS/get_units':
      return pipe(s, memoed.step(Loading))
    case 'UNITS/get_units_result':
      return pipe(s, memoed.step(a.payload))
    default:
      return s
  }
}

function* getUnitsSaga(
  _: t.GetUnitsAction,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  yield yield pipe(
    api.getUnits(token),
    taskEither.map(record.map(t.unitToUnitInfo)),
    task.map(foldEitherToAction(t.getUnitsResult)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  )
}

export function* saga() {
  yield effects.all([
    takeLatest('UNITS/get_units', getUnitsSaga),
    effects.put(t.getUnitsAction()),
  ])
}
