import React, { useCallback } from 'react'
import { Select } from 'antd'
import styled from 'styled-components'
import { CountrySelectComponentProps } from 'react-phone-number-input'

const CustomSelect = styled(Select)`
  .ant-select-selection {
    background-color: green;
  }
  & .ant-select-selector {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
`

const CountrySelect = ({
  value,
  onChange,
  options,
  ...rest
}: CountrySelectComponentProps) => {
  if (value === undefined) {
    value = 'ZZ'
  }

  console.log(value)
  const onChange_ = useCallback(
    (value) => {
      if (!!onChange) {
        onChange(value === 'ZZ' ? undefined : value)
      }
    },
    [onChange]
  )

  return (
    <CustomSelect
      {...rest}
      style={{ width: 60, borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
      value={value || 'ZZ'}
      onChange={onChange_}
    >
      {(options ?? [])
        .filter((e) => e.label != 'International')
        .map(({ value }) => (
          <Select.Option
            key={value ?? 'ZZ'}
            value={value ?? 'ZZ'}
            style={{ width: 60 }}
          >
            <div className="PhoneInputCountryIconUnicode">
              <img src={`https://flag.pk/flags/4x3/${value}.svg`} />
            </div>
          </Select.Option>
        ))}
    </CustomSelect>
  )
}

export default CountrySelect
