import { UserOutlined } from '@ant-design/icons'
import { UAAAccount, useAccount } from '@library/react-toolkit'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Tag,
  Upload,
} from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { array, option, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import {
  updateAccountAction,
  updateSelectedFile,
  uploadImageAction,
} from '../types'
import AddPhonenumberModal from './AddPhonenumberModal'

const Save = styled(Button)`
  width: 251px;
  height: 32px;
`

type AccountWithLangKey = Omit<UAAAccount, 'langKey'> & { langKey: string }

const PersonalInformationFormComponent = () => {
  const dispatch = useDispatch()

  const [form] = Form.useForm<AccountWithLangKey>()

  // TODO: drop this type assertion once react-toolkit is updated
  const accountWithOptionalTypes = useAccount() as
    | (UAAAccount & { phonenumber?: string })
    | undefined

  const account = {
    ...accountWithOptionalTypes,
    langKey: pipe(
      accountWithOptionalTypes?.langKey ?? option.none,
      option.getOrElse(() => 'en')
    ),
  }

  console.log(account)

  const imageUrl = useSelector((s: State) =>
    pipe(
      s.accountSetting.selectedFile,
      option.map((f) => URL.createObjectURL(f)),
      option.alt(() =>
        pipe(
          option.fromNullable(account?.imageUrl),
          option.compact,
          option.map((imageUrl) => `/images/uaa/${imageUrl}`)
        )
      )
    )
  )

  const imageLoading = useSelector((s: State) =>
    loadable.isLoading(s.accountSetting.uploadImageResult)
  )

  const selectedImage = useSelector((s: State) =>
    option.toUndefined(s.accountSetting.selectedFile)
  )

  React.useEffect(() => {
    form.resetFields()
  }, [form, account])

  return (
    <div>
      <Row gutter={[40, 30]}>
        <Col span={4} style={{ marginLeft: 30 }}>
          <TranslatedMessage id="picture" />
        </Col>
        <Col span={4}>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={(file) => {
              if (file.type !== 'image/jpeg') {
                message.error('You can only upload JPG files!')
                return false
              }
              if (file.size > 2 * 1024 * 1024) {
                message.error('Image must smaller than 2MB!')
                return false
              }
              dispatch(updateSelectedFile(file))
              return false
            }}
          >
            {pipe(
              imageUrl,
              option.fold(
                () => (
                  <div>
                    <Avatar
                      size="large"
                      icon={
                        <UserOutlined
                          style={{
                            color: 'white',
                          }}
                        />
                      }
                    />
                    <div style={{ marginTop: 8 }}>
                      <TranslatedMessage id="selectPicture" />
                    </div>
                  </div>
                ),
                (imageUrl) => <img src={imageUrl} style={{ width: '100%' }} />
              )
            )}
          </Upload>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col>
          <Save
            loading={imageLoading}
            type="primary"
            disabled={!selectedImage}
            onClick={() => {
              if (!selectedImage) {
                return
              }
              const file = new FormData()
              file.append('file', selectedImage)
              dispatch(uploadImageAction({ file }))
            }}
          >
            <TranslatedMessage id="save" />
          </Save>
        </Col>
      </Row>
      <Form
        initialValues={account}
        onFinish={({ organizations: _, langKey, ...rest }) =>
          dispatch(
            updateAccountAction({ ...rest, langKey: option.some(langKey) })
          )
        }
        form={form}
        labelCol={{ lg: 10, md: 8 }}
        wrapperCol={{ span: 12 }}
      >
        <Row justify="start">
          <Col lg={12} md={24}>
            <Form.Item
              label={<TranslatedMessage id="emailLabel" />}
              name="email"
              rules={[
                {
                  required: true,
                  message: <TranslatedMessage id="missingEmailMsg" />,
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={<TranslatedMessage id="phonenumberLabel" />}
              name="phonenumber"
            >
              <AddPhonenumberModal phonenumber={account.phonenumber} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={24}>
            <Form.Item
              label={<TranslatedMessage id="firstName" />}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: <TranslatedMessage id="missingFirstnameMsg" />,
                },
              ]}
            >
              <Input placeholder="FirstName" />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={<TranslatedMessage id="lastName" />}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: <TranslatedMessage id="missingLastnameMsg" />,
                },
              ]}
            >
              <Input placeholder="LasName" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={24}>
            <Form.Item label={<TranslatedMessage id="organizationLabel" />}>
              {pipe(
                record.toArray(account?.organizations ?? {}),
                array.map(([org, _]) => <Tag key={org}>{org}</Tag>)
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Form.Item
              name="langKey"
              label={<TranslatedMessage id="language" />}
            >
              <Radio.Group>
                <Radio value="en">
                  <TranslatedMessage id="en" />
                </Radio>
                <Radio value="fr">
                  <TranslatedMessage id="fr" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[8, 8]}>
          <Col>
            <Save htmlType="submit" type="primary" loading={false}>
              <TranslatedMessage id="save" />
            </Save>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
export default PersonalInformationFormComponent
