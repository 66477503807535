import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  matchPath,
  Redirect,
  Route,
  RouteProps,
  useLocation,
} from 'react-router-dom'

import { loadable } from '../types'

type Props = RouteProps

const PublicRoute = ({ ...rest }: Props) => {
  const isLoggedIn = useSelector((s) => loadable.isOk(s.prosperus.account))
  const location = useLocation()

  const shouldRedirect =
    isLoggedIn && !matchPath(location.pathname, '/auth/signup')

  return shouldRedirect ? <Redirect to="/" /> : <Route {...rest} />
}

export default PublicRoute
