import { LeftOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  Row,
  Typography,
} from 'antd'
import { goBack } from 'connected-react-router'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import { requestTFAAction } from './types'

const mapStateToProps = ({
  prosperus: { account },
  locale,
  tfaRequest: { requestResult },
}: State) => ({
  locale,
  loading: loadable.isLoading(account) || loadable.isLoading(requestResult),
  error: pipe(
    requestResult,
    loadable.caseOf({ Err: () => true, _: () => false })
  ),
  success: loadable.isOk(requestResult),
})

const mapDispatchToProps = {
  goBack,
  confirm: requestTFAAction,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const EmailForm = styled((p: FormProps<{ email: string }>) => (
  <Form<{ email: string }> {...p} />
))`
  & > .ant-alert {
    margin-bottom: 16px;
  }
`

const TFAEmail = ({
  loading,
  locale,
  goBack,
  confirm,
  error,
  success,
}: Props) => (
  <>
    <Row gutter={[8, 32]}>
      <Col span={24}>
        <Card hoverable>
          <Typography.Title level={4}>
            <TranslatedMessage id="resendTFACode" />
          </Typography.Title>
          <EmailForm onFinish={confirm}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: <TranslatedMessage id="requiredEmail" />,
                },
                {
                  type: 'email',
                  message: <TranslatedMessage id="invalidEmail" />,
                },
              ]}
            >
              <Input
                placeholder={translatedMessage(locale)('email')}
                disabled={loading}
              />
            </Form.Item>
            {error && (
              <Alert
                message={<TranslatedMessage id="tfaRequestFailed" />}
                type="error"
              />
            )}
            {success && (
              <Alert
                message={<TranslatedMessage id="tfaRequestSuccess" />}
                type="success"
              />
            )}
            <Row justify="space-between">
              <Button type="link" onClick={goBack}>
                <LeftOutlined />
                <TranslatedMessage id="cancel" />
              </Button>
              <Button type="primary" htmlType="submit">
                <TranslatedMessage id="resend" />
              </Button>
            </Row>
          </EmailForm>
        </Card>
      </Col>
    </Row>
  </>
)

export default connect(mapStateToProps, mapDispatchToProps)(TFAEmail)
