import * as t from 'io-ts'
import { optionFromNullable } from 'io-ts-types/lib/optionFromNullable'
import { array, record, option, eq, ord } from 'fp-ts'
import { Monoid, monoidAny, monoidSum, monoidAll } from 'fp-ts/lib/Monoid'
import { pipe } from 'fp-ts/lib/pipeable'
import { DateString } from './Codec'
import { ApplicationInfo } from '../../shared/state/Info'

const protocolVersion = 'dcn1.0'

export const SignatureInfo = t.type({
  signature: t.string,
  clauses: t.array(t.string),
})

export type SignatureInfo = t.TypeOf<typeof SignatureInfo>

export const eqSignatureInfo: eq.Eq<SignatureInfo> = eq.getStructEq({
  signature: eq.eqString,
  clauses: array.getEq(eq.eqString),
})

export const Signatures = t.record(t.string, SignatureInfo, 'Signatures')

export type Signatures = t.TypeOf<typeof Signatures>

export const eqSignatures: eq.Eq<Signatures> = record.getEq(eqSignatureInfo)

export const TransactionResult = t.type({
  success: t.boolean,
  error: optionFromNullable(t.string),
  uuid: t.string,
})

export type TransactionResult = t.TypeOf<typeof TransactionResult>

export const eqTransactionResult: eq.Eq<TransactionResult> = eq.getStructEq({
  success: eq.eqBoolean,
  error: option.getEq(eq.eqString),
  uuid: eq.eqString,
})

export const Balance = t.record(t.string, t.number)

export const eqBalance: eq.Eq<Balance> = record.getEq(eq.eqNumber)

const compareBalances = (a: Balance, b: Balance) =>
  allLess(a)(b) ? -1 : allLess(b)(a) ? 1 : 0

/**
 * Ord instance for balance, a is inferior to b if and only if all amounts in a
 * are inferior to b
 */
export const ordBalance: ord.Ord<Balance> = {
  equals: (a, b) => compareBalances(absBalance(a), absBalance(b)) === 0,
  compare: compareBalances,
}

export const getAmount = (asset: string) => (a: Balance) =>
  pipe(
    record.lookup(asset, a),
    option.getOrElse(() => 0)
  )

const allLess = (a: Balance) => (b: Balance) => {
  const balanceA = (asset: string) => getAmount(asset)(a)
  return pipe(
    b,
    record.foldMapWithIndex(monoidAll)(
      (asset, amount) => amount > balanceA(asset)
    )
  )
}

export const anyLess = (a: Balance) => (b: Balance) => {
  const balanceA = (asset: string) => getAmount(asset)(a)
  return pipe(
    b,
    record.foldMapWithIndex(monoidAny)(
      (asset, amount) => amount > balanceA(asset)
    )
  )
}

export const balanceSumMonoid: Monoid<Balance> = record.getMonoid(monoidSum)

export type Balance = t.TypeOf<typeof Balance>

export const Metadata = t.type({
  timestamp: t.string,
  location: t.string,
  message: t.string,
  terms: t.string,
  clientinfo: t.type({
    imei: optionFromNullable(t.string),
    ip: optionFromNullable(t.string),
    other: optionFromNullable(t.string),
  }),
  other: t.string,
  signatureinfo: SignatureInfo,
})

export const emptyMetadata: Readonly<Metadata> = {
  timestamp: '',
  location: '',
  message: '',
  terms: '',
  clientinfo: { imei: option.none, ip: option.none, other: option.none },
  other: '',
  signatureinfo: { signature: '', clauses: [] },
}

export type Metadata = t.TypeOf<typeof Metadata>

export const eqMetadata: eq.Eq<Metadata> = eq.getStructEq({
  timestamp: eq.eqString,
  location: eq.eqString,
  message: eq.eqString,
  terms: eq.eqString,
  clientinfo: eq.getStructEq({
    imei: option.getEq(eq.eqString),
    ip: option.getEq(eq.eqString),
    other: option.getEq(eq.eqString),
  }),
  other: eq.eqString,
  signatureinfo: eqSignatureInfo,
})

export const Peer = t.type(
  {
    transferQuantity: Balance,
    transferSequence: optionFromNullable(t.number),
    metadata: optionFromNullable(Metadata),
  },
  'Peer'
)

export type Peer = t.TypeOf<typeof Peer>

export const eqPeer: eq.Eq<Peer> = eq.getStructEq({
  transferQuantity: eqBalance,
  transferSequence: option.getEq(eq.eqNumber),
  metadata: option.getEq(eqMetadata),
})

export const Peers = t.record(t.string, Peer, 'Peers')

export type Peers = t.TypeOf<typeof Peers>

export const eqPeers: eq.Eq<Peers> = record.getEq(eqPeer)

export const Transfer = t.type(
  {
    type: t.literal('transfer'),
    protocolVersion: t.literal(protocolVersion),
    dcnNymID: t.string,
    signatures: Signatures,
    context: t.string,
    transactionResult: optionFromNullable(TransactionResult),
    peers: Peers,
  },
  'Transfer'
)

export type Transfer = t.TypeOf<typeof Transfer>

export const eqTransfer: eq.Eq<Transfer> = eq.getStructEq({
  type: eq.eqString,
  protocolVersion: eq.eqString,
  dcnNymID: eq.eqString,
  signatures: eqSignatures,
  context: eq.eqString,
  transactionResult: option.getEq(eqTransactionResult),
  peers: eqPeers,
})

export const Bill = t.type(
  {
    requesterID: t.string,
    amount: t.number,
    comment: t.string,
    asset: t.string,
    wallet: t.string,
    createdAt: DateString,
    updatedAt: DateString,
    status: t.keyof({
      /* eslint-disable @typescript-eslint/naming-convention */
      paid: null,
      pending_payment: null,
      pending_validation: null,
      /* eslint-enable @typescript-eslint/naming-convention */
    }),
    reference: t.string,
    requestURL: t.string,
  },
  'Bill'
)
export const emptyBill: Bill = {
  requesterID: '',
  amount: 0,
  comment: '',
  asset: '',
  wallet: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  status: 'pending_payment',
  reference: '',
  requestURL: '',
}

const info: ApplicationInfo = {
  env: 'dev',
  platform: 'tpay',
}

const ecommerceURL = () =>
  info.platform === 'sola'
    ? ''
    : `https://${info.env}-apps.prosperus.tech${
        info.env !== 'dev' ? ':3004' : ''
      }`

export const billURL = (b: Bill) => {
  const baseURL = new URL(ecommerceURL())
  baseURL.pathname = 'payment'
  baseURL.searchParams.set('redirectURL', window.location.toString())
  baseURL.searchParams.set('reference', b.reference)
  baseURL.searchParams.set('nym', b.wallet)
  return baseURL.toString()
}

export const eqBill: eq.Eq<Bill> = {
  equals: (a, b) => a.reference === b.reference,
}

export type Bill = t.TypeOf<typeof Bill>

export const EnrichedTransfer = t.type({
  transfer: Transfer,
  timestamp: DateString,
  status: t.keyof({
    pending: null,
    failed: null,
    success: null,
    cancelled: null,
  }),
  bill: optionFromNullable(Bill),
})

export type EnrichedTransfer = t.TypeOf<typeof EnrichedTransfer>

export const eqEnrichedTransfer: eq.Eq<EnrichedTransfer> = eq.getStructEq({
  transfer: eqTransfer,
  timestamp: eq.eqDate,
  status: eq.eqString,
  bill: option.getEq(eqBill),
})

export const enrichedTransferOrd: ord.Ord<EnrichedTransfer> = ord.contramap<
  Date,
  EnrichedTransfer
>((t) => t.timestamp)(ord.ordDate)

const absBalance = record.map(Math.abs)

export const ordPeerBalances: ord.Ord<Peer> = ord.contramap<Balance, Peer>(
  (p) => absBalance(p.transferQuantity)
)(ordBalance)

export const ordPeerBalancesWithPriority = (
  nym: string
): ord.Ord<[string, Peer]> => ({
  equals: (a, b) => ordPeerBalances.equals(a[1], b[1]),
  compare: (a, b) => (a[0] === nym ? 1 : ordPeerBalances.compare(a[1], b[1])),
})
