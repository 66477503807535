import { CancelToken } from 'axios'
import { pipe } from 'fp-ts/lib/function'
import { lens } from 'lens.ts'

import { Loading, NotRequested } from '../../types/Loadable'
import * as api from './api'
import * as t from './types'
import * as effects from 'redux-saga/effects'
import { takeLatest } from '../saga'
import { task } from 'fp-ts'
import {
  foldEitherToAction,
  getAccountAction,
  getCurrentWorkspaceAction,
} from '@library/react-toolkit'

export const initialState: t.State = {
  updateResult: NotRequested,
}
const _l = lens<t.State>()

export const reducer = (s: t.State = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'ORGANIZATION/update_organization':
      return pipe(s, _l.updateResult.set(Loading))
    case 'ORGANIZATION/update_organization_result':
      return pipe(s, _l.updateResult.set(a.payload))
    case 'ORGANIZATION/update_organization_modules':
      return pipe(s, _l.updateResult.set(Loading))
    case 'ORGANIZATION/update_organization_modules_result':
      return pipe(s, _l.updateResult.set(a.payload))
    default:
      return s
  }
}

function* updateOrganizationSaga(
  a: t.UpdateOrganizationAction,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  yield yield effects.call(
    pipe(
      api.updateOrganization(a.payload, token),
      task.map(foldEitherToAction(t.updateOrganizationResultAction)),
      task.map((a) =>
        a.payload._tag === 'Ok'
          ? effects.all([
              effects.put(a),
              effects.put(getCurrentWorkspaceAction()),
            ])
          : effects.all([effects.put(a)])
      )
    )
  )
}

function* updateOrganizationModulesSaga(
  a: t.UpdateOrganizationModulesAction,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  yield yield effects.call(
    pipe(
      api.updateOrganizationModules(a.payload, token),
      task.map(foldEitherToAction(t.updateOrganizationModulesResultAction)),
      task.map((a) =>
        a.payload._tag === 'Ok'
          ? effects.all([
              effects.put(a),
              effects.put(getCurrentWorkspaceAction()),
              effects.put(getAccountAction()),
            ])
          : effects.all([effects.put(a)])
      )
    )
  )
}

export function* saga() {
  yield effects.all([
    takeLatest('ORGANIZATION/update_organization', updateOrganizationSaga),
    takeLatest(
      'ORGANIZATION/update_organization_modules',
      updateOrganizationModulesSaga
    ),
  ])
}
