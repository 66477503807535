import { Button, Card, Typography } from 'antd'
import * as React from 'react'

import { Link, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { TranslatedMessage } from '../../../shared/translations/data'
import Finish from './Finish'
import Init from './Init'

const ResetPasswordCard = styled(Card)`
  min-width: 512px;
  max-width: 512px;
`
const StepContainer = styled.div`
  width: 200%;
  & > * {
    width: 50%;
    padding: 0 4px;
  }
  margin-bottom: 24px;
`

const ResetPassword = () => (
  <ResetPasswordCard bordered={false}>
    <StepContainer>
      <Switch>
        <Route exact={true} path="/auth/reset/init" component={Init} />
        <Route exact={true} path="/auth/reset/finish" component={Finish} />
      </Switch>
    </StepContainer>
    <Typography.Paragraph style={{ textAlign: 'center' }}>
      <TranslatedMessage id="createAccountQuestion" />
      <Link to="/auth/signup">
        <Button type="link" color="primary">
          <TranslatedMessage id="signUp" />
        </Button>
      </Link>
    </Typography.Paragraph>
  </ResetPasswordCard>
)

export default ResetPassword
