import { Alert, Button, Col, Divider, Form, Input, Row, Typography } from 'antd'
import { option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import { changePasswordAction, PasswordForm } from '../types'
import TwoFactorSwitch from './TwoFactorSwitch'

const { Title } = Typography

const TitleS = styled(Title)`
  color: #51b148 !important;

  font-size: 21px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 2px;
`
const Save = styled(Button)`
  width: 251px;
  height: 32px;
`

const SecuritySettingsForm = () => {
  const [form] = Form.useForm()
  const [validate, setValidate] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)

  React.useEffect(() => {
    form
      .validateFields()
      .then(() => setIsValid(true))
      .catch(() => setIsValid(false))
  }, [validate])
  const locale = useSelector((s: State) => s.locale)

  const error = useSelector((s: State) =>
    pipe(
      s.accountSetting.changePasswordResult,
      loadable.caseOf({ Err: (e) => option.some(e), _: () => option.none })
    )
  )

  const loading = useSelector((s: State) =>
    loadable.isLoading(s.accountSetting.changePasswordResult)
  )

  const success = useSelector((s: State) =>
    loadable.isOk(s.accountSetting.changePasswordResult)
  )

  const dispatch = useDispatch()
  return (
    <>
      <Form
        form={form}
        onFinish={(e: PasswordForm) => {
          return dispatch(
            changePasswordAction({
              currentPassword: e.currentPassword,
              newPassword: e.newPassword,
              confirmPassword: e.confirmPassword,
            })
          )
        }}
        name="control-hooks"
      >
        <Row>
          <Col span={24}>
            <Row gutter={[40, 30]}>
              <Col span={24}>
                <TitleS level={4}>
                  <TranslatedMessage id="changePassword" />
                </TitleS>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[40, 30]}>
              <Col span={24}>
                <Form.Item
                  shouldUpdate
                  help={
                    !form.getFieldValue('currentPassword') &&
                    form.isFieldTouched('currentPassword') ? (
                      <TranslatedMessage id="requiredPassword" />
                    ) : (
                      <></>
                    )
                  }
                  label={<TranslatedMessage id="currentPassword" />}
                  name="currentPassword"
                  rules={[{ required: true }]}
                  hasFeedback
                  validateStatus={
                    form.isFieldTouched('currentPassword') &&
                    form.getFieldValue('currentPassword')?.length <= 0
                      ? 'success'
                      : ''
                  }
                >
                  {
                    <Input
                      value={form.getFieldValue('currentPassword')}
                      placeholder={translatedMessage(locale)('currentPassword')}
                      disabled={loading}
                      onChange={(e) => {
                        form.setFieldsValue({ currentPassword: e.target.value })
                        setValidate(!validate)
                      }}
                      type="password"
                    />
                  }
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  help={
                    !form.getFieldValue('newPassword') &&
                    form.isFieldTouched('newPassword') ? (
                      <TranslatedMessage id="requiredPassword" />
                    ) : form.getFieldValue('newPassword')?.length < 8 ? (
                      <TranslatedMessage id="invalidPassword" />
                    ) : (
                      <></>
                    )
                  }
                  label={<TranslatedMessage id="newPassword" />}
                  name="newPassword"
                  rules={[{ required: true, min: 8 }]}
                  hasFeedback
                  validateStatus={
                    form.isFieldTouched('newPassword') &&
                    form.getFieldValue('newPassword')?.length < 8
                      ? 'error'
                      : form.isFieldTouched('newPassword') &&
                        form.getFieldValue('newPassword')?.length >= 8
                      ? 'success'
                      : ''
                  }
                >
                  <Input
                    value={form.getFieldValue('newPassword')}
                    placeholder={translatedMessage(locale)('newPassword')}
                    disabled={loading}
                    onChange={(e) => {
                      form.setFieldsValue({ newPassword: e.target.value })
                      setValidate(!validate)
                    }}
                    type="password"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  help={
                    !form.getFieldValue('confirmPassword') &&
                    form.isFieldTouched('confirmPassword') ? (
                      <TranslatedMessage id="requiredPassword" />
                    ) : form.getFieldValue('confirmPassword') !==
                      form.getFieldValue('newPassword') ? (
                      <TranslatedMessage id="mismatchedPasswords" />
                    ) : (
                      <></>
                    )
                  }
                  rules={[{ required: true, min: 8 }]}
                  hasFeedback
                  label={<TranslatedMessage id="confirmPassword" />}
                  name="confirmPassword"
                  validateStatus={
                    form.isFieldTouched('confirmPassword') &&
                    form.getFieldValue('confirmPassword') !==
                      form.getFieldValue('newPassword')
                      ? 'error'
                      : form.isFieldTouched('confirmPassword') &&
                        form.getFieldValue('confirmPassword') !==
                          form.getFieldValue('newPassword')
                      ? 'success'
                      : ''
                  }
                >
                  <Input
                    value={form.getFieldValue('confirmPassword')}
                    placeholder={translatedMessage(locale)('confirmPassword')}
                    disabled={loading}
                    onChange={(e) => {
                      form.setFieldsValue({
                        confirmPassword: e.target.value,
                      })
                      setValidate(!validate)
                    }}
                    type="password"
                  />
                </Form.Item>
                {option.isSome(error) && (
                  <Alert
                    message={<TranslatedMessage id="changeRequestFailed" />}
                    type="error"
                  />
                )}
                {success && (
                  <Alert
                    message={<TranslatedMessage id="changeRequestSuccess" />}
                    type="success"
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <Col>
            <Save
              disabled={
                !isValid ||
                form.getFieldValue('confirmPassword') !==
                  form.getFieldValue('newPassword')
              }
              htmlType="submit"
              type="primary"
            >
              <TranslatedMessage id="save" />
            </Save>
          </Col>
        </Row>
      </Form>
      <Divider />
      <TwoFactorSwitch />
    </>
  )
}
export default SecuritySettingsForm
