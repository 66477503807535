import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { State } from '../state/store'
import { loadable } from '../types'

type Props = RouteProps

const PrivateRoute = ({ ...rest }: Props) => {
  const isLoggedOut = useSelector((s: State) =>
    pipe(
      s.prosperus.account,
      loadable.caseOf({ Ok: () => false, Loading: () => false, _: () => true })
    )
  )

  return isLoggedOut ? <Redirect exact to="/auth/login" /> : <Route {...rest} />
}

export default PrivateRoute
