import * as axios from '../../../axios'
import * as t from 'io-ts'
import { CancelToken } from 'axios'
import { FinishReset, ResetRequestPassword } from './types'
import { pipe } from 'fp-ts/lib/pipeable'
import { taskEither } from 'fp-ts'

const apiUrl = '/uaa/api/account/reset-password'

export const resetPassword = (
  payload: ResetRequestPassword,
  cancelToken?: CancelToken
) =>
  pipe(
    axios.post(`${apiUrl}/init`, payload, { cancelToken, decoder: t.unknown }),
    taskEither.map(() => undefined)
  )

export const confirmResetPassword = (
  req: FinishReset,
  cancelToken?: CancelToken
) =>
  pipe(
    axios.post(`${apiUrl}/finish`, req, { cancelToken, decoder: t.unknown }),
    taskEither.map(() => undefined)
  )
