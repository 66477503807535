import {
  useOrganization,
  getCurrentWorkspaceAction,
} from '@library/react-toolkit'
import { pipe } from 'fp-ts/lib/function'
import { useDispatch } from 'react-redux'
import { useMutation } from 'react-query'
import { uploadOrgImage } from './api'
import { taskEither, task, either } from 'fp-ts'

export const throwLeft: <L, R>(
  ma: taskEither.TaskEither<L, R>
) => task.Task<R> = task.map(
  either.fold(
    (e) => {
      throw e
    },
    (x) => x
  )
)

export const useOrganizationImageMutation = () => {
  const org = useOrganization()
  const dispatch = useDispatch()
  return useMutation(
    (file: FormData) =>
      pipe(uploadOrgImage(org?.name ?? '', file), throwLeft)(),
    {
      onSuccess: () => {
        dispatch(getCurrentWorkspaceAction())
      },
    }
  )
}
