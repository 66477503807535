import { monad, functor, either as E, eq, show } from 'fp-ts'
import * as t from 'io-ts'
import { pipeable } from 'fp-ts/lib/pipeable'
import { Either } from 'fp-ts/lib/Either'
import { identity } from 'fp-ts/lib/function'

export const URI = 'Result'

export type URI = typeof URI

export const ErrC = t.type({
  _tag: t.literal('Err'),
  _URI: t.literal(URI),
  value: t.any,
})

export type Err = {
  readonly _tag: 'Err'
  readonly _URI: URI
  readonly value: any
}

export const Err = (e: unknown): Err => ({
  _tag: 'Err',
  _URI: URI,
  value: e,
})

export const OkC = <C extends t.Mixed>(c: C) =>
  t.type({
    _tag: t.literal('Ok'),
    _URI: t.literal(URI),
    value: c,
  })

export type Ok<A> = {
  readonly _tag: 'Ok'
  readonly _URI: URI
  readonly value: A
}

export const Ok = <A>(x: A): Ok<A> => ({
  _tag: 'Ok',
  _URI: URI,
  value: x,
})

export const ResultC = <C extends t.Mixed>(c: C) => t.union([ErrC, OkC(c)])

export type Result<A> = Err | Ok<A>

const getEq = <A>(e: eq.Eq<A>): eq.Eq<Result<A>> => ({
  equals: (a, b) =>
    a._tag === 'Ok' && b._tag === 'Ok'
      ? e.equals(a.value, b.value)
      : a._tag === b._tag,
})

const ap = <A, B>(fab: Result<(a: A) => B>, fa: Result<A>): Result<B> =>
  fab._tag === 'Ok' ? (fa._tag === 'Ok' ? Ok(fab.value(fa.value)) : fa) : fab

const map = <A, B>(fa: Result<A>, fab: (a: A) => B) =>
  fa._tag === 'Ok' ? Ok(fab(fa.value)) : fa

const of = <T>(a: T) => Ok(a)

const chain = <A, B>(fa: Result<A>, fab: (a: A) => Result<B>): Result<B> =>
  fa._tag === 'Ok' ? fab(fa.value) : fa

const isOk = <A>(fa: Result<A>): fa is Ok<A> => fa._tag === 'Ok'

const isErr = <A>(fa: Result<A>): fa is Err => fa._tag === 'Err'

export const caseOf = <A, B>(fa: Result<A>, cases: CaseMatch<A, B>): B =>
  fa._tag === 'Ok' ? cases.Ok(fa.value) : cases.Err(fa.value)

export type CaseMatch<A, B> = { Ok: (_: A) => B; Err: (_: unknown) => B }

declare module 'fp-ts/lib/HKT' {
  interface URItoKind<A> {
    Result: Result<A>
  }
}

const resultM: monad.Monad1<URI> &
  functor.Functor1<URI> & { caseOf: typeof caseOf } = {
  URI,
  of,
  ap,
  map,
  chain,
  caseOf,
}

const getShow = <A>(s: show.Show<A>): show.Show<Result<A>> => ({
  show: (r) =>
    caseOf(r, {
      Ok: (x) => `Ok(${s.show(x)})`,
      Err: (e) => `Err(${e})`,
    }),
})

export const result = {
  ...pipeable(resultM),
  isErr,
  isOk,
  getEq,
  getShow,
  getOrElse:
    <A>(onNone: () => A): ((ma: Result<A>) => A) =>
    (ma: Result<A>) =>
      resultM.caseOf(ma, { Ok: identity, Err: onNone }),
  caseOf:
    <A, B>(cases: CaseMatch<A, B>) =>
    (ma: Result<A>) =>
      resultM.caseOf(ma, cases),
  fromEither: <A, B>(e: Either<B, A>): Result<A> =>
    E.fold<B, A, Result<A>>(Err, Ok)(e),
}
