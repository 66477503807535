import * as t from './types'
import * as effects from 'redux-saga/effects'
import * as api from './api'
import { NotRequested, Loading, result } from '../../../shared/types'
import { pipe } from 'fp-ts/lib/function'
import { task } from 'fp-ts'
import { lens } from 'lens.ts'
import { CancelToken } from 'axios'
import { takeLatest } from '../../../shared/state/saga'
import { push } from 'connected-react-router'
import { foldEitherToAction } from '@library/react-toolkit'

export const initialState: t.State = {
  initRequestResult: NotRequested,
  finishRequestResult: NotRequested,
}

const _l = lens<t.State>()

export const reducer = (s = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'RESET/confirm_init_request':
      return pipe(s, _l.initRequestResult.set(Loading))
    case 'RESET/init_request_result':
      return pipe(s, _l.initRequestResult.set(a.payload))
    case 'RESET/confirm_finish_request':
      return pipe(s, _l.finishRequestResult.set(Loading))
    case 'RESET/finish_request_result':
      return pipe(s, _l.finishRequestResult.set(a.payload))
    default:
      return s
  }
}

function* confirmInitSaga(
  a: t.ConfirmInitRequest,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  yield yield effects.call(
    pipe(
      api.resetPassword(a.payload, token),
      task.map(foldEitherToAction(t.initRequestResult)),
      task.map((a) => effects.put(a))
    )
  )
}

function* confirmFinishSaga(
  a: t.ConfirmFinishRequest,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  yield yield pipe(
    api.confirmResetPassword(a.payload, token),
    task.map(foldEitherToAction(t.finishRequestResult)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  )
}

function* resetRequestSaga(a: t.FinishRequestResult) {
  if (result.isErr(a.payload)) {
    return
  }
  yield effects.delay(5000)
  yield effects.put(push('/'))
}

export function* saga() {
  yield effects.all([
    takeLatest('RESET/confirm_init_request', confirmInitSaga),
    takeLatest('RESET/confirm_finish_request', confirmFinishSaga),
    takeLatest('RESET/finish_request_result', resetRequestSaga),
  ])
}
