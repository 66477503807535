import * as axios from '../../../axios'
import * as t from 'io-ts'
import { CancelToken } from 'axios'
import { pipe } from 'fp-ts/lib/function'
import { taskEither } from 'fp-ts'

export const requestTFACode = (email: string, cancelToken?: CancelToken) =>
  pipe(
    axios.post('/uaa/api/account/request-tfa-code', email, {
      cancelToken,
      headers: { 'Content-Type': 'text/plain' },
      decoder: t.unknown,
    }),
    taskEither.map(() => undefined)
  )
