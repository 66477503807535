import { Organization, UAAAccount } from '@library/react-toolkit'
import { either, option, record, task } from 'fp-ts'
import { flow, pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

import * as axios from '../../../axios'
import { Login, LoginStatus, SignUp } from './types'

const unpackLoginError = flow(
  option.fromPredicate(axios.isAxiosError),
  option.chain((e) => option.fromNullable(e.response)),
  option.map((e) => e.data),
  option.chain(
    option.fromPredicate(
      (e) =>
        e?.error === 'missing TFA code' || e?.error === 'incorrect TFA code'
    )
  ),
  option.map((): LoginStatus => '2FA')
)

export const signIn = (user: Login, cancelToken?: axios.CancelToken) =>
  pipe(
    axios.post(
      '/auth/login',
      pipe(
        user,
        record.filter((v) => v !== '')
      ),
      { cancelToken, decoder: t.unknown }
    ),
    task.map(either.map(() => ({}))),
    task.map((v) =>
      either.isRight(v)
        ? v
        : pipe(
            unpackLoginError(v.left),
            either.fromOption(() => v.left)
          )
    )
  )

export const googleSignIn = (
  params: URLSearchParams,
  cancelToken?: axios.CancelToken
) =>
  pipe(
    axios.post('/auth/login', params, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      cancelToken,
      decoder: t.unknown,
    }),
    task.map(either.map(() => ({}))),
    task.map((v) =>
      either.isRight(v)
        ? v
        : pipe(
            unpackLoginError(v.left),
            either.fromOption(() => v.left)
          )
    )
  )

export const getAccount = (cancelToken?: axios.CancelToken) =>
  axios.get('/uaa/api/account', { cancelToken, decoder: UAAAccount })

export const logout = (cancelToken?: axios.CancelToken) =>
  axios.post('/auth/logout', {}, { cancelToken, decoder: t.unknown })

export const signup = (signupData: SignUp, cancelToken?: axios.CancelToken) =>
  axios.post('/uaa/api/register', signupData, {
    cancelToken,
    decoder: t.unknown,
  })

export const verifyOrganizationNameExists = (
  name: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/uaa/api/organizations/${name}/exists`, {
    cancelToken,
    decoder: t.unknown,
  })

export const ExistsResponse = t.type({
  login: t.string,
  email: t.string,
})

export const verifyUserExists = (
  name: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/uaa/api/users/${name}/exists`, {
    cancelToken,
    decoder: ExistsResponse,
  })

export const activateAccount = (key: string, cancelToken?: axios.CancelToken) =>
  axios.get(`/uaa/api/activate`, {
    params: { key },
    cancelToken,
    decoder: t.unknown,
  })

type MakeOptional<O extends object, K extends keyof O> = Omit<O, K> &
  Partial<Pick<O, K>>

export const createOrganization = (
  organization: MakeOptional<
    Organization,
    | 'id'
    | 'bankName'
    | 'users'
    | 'url'
    | 'logoUrl'
    | 'iban'
    | 'country'
    | 'activity'
    | 'fiscalYear'
    | 'fiscalId'
    | 'employeesNumber'
  >,
  cancelToken?: axios.CancelToken
) =>
  axios.post(`/uaa/api/organizations`, organization, {
    cancelToken,
    decoder: Organization,
  })
