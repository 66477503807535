import { record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import { UserPermissions } from '../../types'
import {
  applicationDefinitions,
  OrganizationUser,
} from '@library/react-toolkit'

export const toPermission = (
  v: string | undefined
): 'CONTRIBUTOR' | 'VIEWER' | undefined =>
  v === 'CONTRIBUTOR' || v === 'VIEWER' ? v : undefined

export const toWalletsPermission = (
  v: { nym: string; permission: string }[]
) => {
  let permissions = {}
  v.forEach((x) => {
    permissions[x.nym] = x.permission
  })
  return permissions
}
export const fromWalletsPermission = (v: Record<string, any>) => {
  return Object.entries(v).map(([key, value]) => {
    return {
      nym: key,
      permission: value,
    }
  })
}

const toModulePermissions = (
  values: Record<string, any>
): OrganizationUser['modulePermissions'] =>
  // @ts-ignore
  pipe(
    applicationDefinitions,
    record.mapWithIndex((key) => toPermission(values[key]))
  )

export const toOrganizationUser = (
  values: Record<string, any>
): Omit<
  OrganizationUser,
  'langKey' | 'imageUrl' | 'jobTitle' | 'activated'
> => ({
  email: values.email,
  firstName: values.firstName,
  lastName: values.lastName,
  authority: values.admin ? 'ADMIN' : 'USER',
  walletPermissions: toWalletsPermission(values.walletPermissions ?? []),
  modulePermissions: toModulePermissions(values),
  login: values.login,
})

export const toUserPermissions = (
  login: string,
  values: Record<string, any>
): UserPermissions => ({
  login,
  authority: values.admin ? 'ADMIN' : 'USER',
  jobTitle: values.jobTitle,
  walletPermissions: toWalletsPermission(values.walletPermissions ?? []),
  modulePermissions: toModulePermissions(values),
})
