import * as React from 'react'
import { loadable, memoed } from '@library/react-toolkit'
import { Col, Row, Space } from 'antd'
import Table from 'antd/lib/table'
import { useDispatch, useSelector } from 'react-redux'
import NotificationAdd from './modals/NotificationAdd'
import NotificationDelete from './modals/NotificationDelete'
import NotificationEdit from './modals/NotificationEdit'
import { BalanceTrigger, getBalanceTriggers } from '../../types'
import { TranslatedMessage } from '../../../../shared/translations/data'

const NotificationAndAlerts = () => {
  const dispatch = useDispatch()
  const balanceTriggers = useSelector((s) =>
    memoed.extract(s.accountSetting.balanceTriggers)
  )
  const loading = useSelector((s) =>
    loadable.isLoading(s.accountSetting.balanceTriggers.v)
  )
  const addResult = useSelector((s) =>
    loadable.isOk(s.accountSetting.addBalanceTriggerResult)
  )
  const updateResult = useSelector((s) =>
    loadable.isOk(s.accountSetting.updateBalanceTriggerResult)
  )
  const deleteResult = useSelector((s) =>
    loadable.isOk(s.accountSetting.deleteBalanceTriggerResult)
  )

  const [pagination, setPagination] = React.useState({
    page: 0,
    itemsPerPage: 10,
  })

  React.useEffect(() => {
    dispatch(getBalanceTriggers(pagination))
  }, [
    pagination.page,
    pagination.itemsPerPage,
    addResult,
    deleteResult,
    updateResult,
  ])

  return (
    <Row gutter={[30, 30]}>
      <Col span={24}>
        <NotificationAdd />
      </Col>
      <Col span={24}>
        <Table<BalanceTrigger & { key: string }>
          size="small"
          onChange={(p) =>
            setPagination((page) => ({
              page: (p.current ?? page.page + 1) - 1,
              itemsPerPage: p.pageSize ?? page.itemsPerPage,
            }))
          }
          pagination={{
            current: pagination.page + 1,
            pageSize: pagination.itemsPerPage,
            total: balanceTriggers.count,
          }}
          columns={[
            {
              title: <TranslatedMessage id="name" />,
              dataIndex: 'name',
              key: 'name',
              align: 'left',
            },
            {
              title: <TranslatedMessage id="module" />,
              dataIndex: 'module',
              key: 'module',
              align: 'left',
            },
            {
              title: <TranslatedMessage id="type" />,
              dataIndex: 'type',
              key: 'type',
              align: 'left',
              render: (_, trigger) => (
                <Row justify="start">
                  {trigger.type === 'Max' ? (
                    <TranslatedMessage id="maxBalance" />
                  ) : (
                    <TranslatedMessage id="minBalance" />
                  )}
                </Row>
              ),
            },
            {
              key: 'action',
              align: 'left',
              render: (_, trigger) => (
                <Row justify="end">
                  <Space size="middle">
                    <NotificationDelete trigger={trigger} />
                    <NotificationEdit trigger={trigger} />
                  </Space>
                </Row>
              ),
            },
          ]}
          dataSource={balanceTriggers.data.map((t) => ({
            ...t,
            key: t.uuid,
            module: <TranslatedMessage id="WEB_WALLET" />,
          }))}
          loading={loading}
        />
      </Col>
    </Row>
  )
}
export default NotificationAndAlerts
