import * as r from './Result'
import * as t from 'io-ts'
import { pipeable, pipe } from 'fp-ts/lib/pipeable'
import { Option } from 'fp-ts/lib/Option'
import {
  monad,
  functor,
  option,
  record,
  eq,
  monoid,
  semigroup,
  show,
} from 'fp-ts'
import { identity } from 'fp-ts/lib/function'

export const URI = 'Loadable'
export type URI = typeof URI

export const NotRequestedC = t.type({
  _tag: t.literal('NotRequested'),
  _URI: t.literal(URI),
})

export const NotRequested: NotRequested = {
  _tag: 'NotRequested',
  _URI: URI,
}

export type NotRequested = Readonly<{
  readonly _tag: 'NotRequested'
  readonly _URI: URI
}>

export const LoadingC = t.type({
  _tag: t.literal('Loading'),
  _URI: t.literal(URI),
})

export type Loading = Readonly<{
  readonly _tag: 'Loading'
  readonly _URI: URI
}>

export const Loading: Loading = { _tag: 'Loading', _URI: URI }

export const LoadableC = <C extends t.Mixed>(c: C) =>
  t.union([r.ResultC(c), LoadingC, NotRequestedC])

export type Loadable<A> = r.Result<A> | Loading | NotRequested

const getEq = <A>(e: eq.Eq<A>): eq.Eq<Loadable<A>> => ({
  equals: (a, b) => {
    return caseOf(a, {
      Ok: (x) => caseOf(b, { Ok: (y) => e.equals(x, y), _: () => false }),
      Loading: () => b._tag === 'Loading',
      NotRequested: () => b._tag === 'NotRequested',
      Err: () => b._tag === 'Err',
    })
  },
})

const getShow = <A>(s: show.Show<A>): show.Show<Loadable<A>> => ({
  show: (r) =>
    caseOf(r, {
      Ok: (x) => `Ok(${s.show(x)})`,
      Err: (e) => `Err(${e})`,
      Loading: () => 'Loading',
      NotRequested: () => 'NotRequested',
    }),
})

const getMonoid = <A>(
  m: semigroup.Semigroup<A>
): monoid.Monoid<Loadable<A>> => ({
  empty: NotRequested,
  concat: (a, b) => chain(a, (a) => map(b, (b) => m.concat(a, b))),
})

const ap = <A, B>(fab: Loadable<(a: A) => B>, fa: Loadable<A>): Loadable<B> =>
  fab._tag === 'Ok' ? (fa._tag === 'Ok' ? r.Ok(fab.value(fa.value)) : fa) : fab

const map = <A, B>(fa: Loadable<A>, fab: (a: A) => B): Loadable<B> =>
  fa._tag === 'Ok' ? r.Ok(fab(fa.value)) : fa

const of = <T>(a: T): Loadable<T> => r.Ok<T>(a)

const chain = <A, B>(
  fa: Loadable<A>,
  fab: (a: A) => Loadable<B>
): Loadable<B> => (fa._tag === 'Ok' ? fab(fa.value) : fa)

const caseOf = <A, B>(x: Loadable<A>, cases: CaseMatch<A, B>): B => {
  switch (x._tag) {
    case 'Ok':
      if (!!cases.Ok) {
        return cases.Ok(x.value)
      }
      break
    case 'Err':
      if (!!cases.Err) {
        return cases.Err(x.value)
      }
      break
    case 'Loading':
      if (!!cases.Loading) {
        return cases.Loading()
      }
      break
    case 'NotRequested':
      if (!!cases.NotRequested) {
        return cases.NotRequested()
      }
      break
  }
  if (!!cases._) {
    return cases._()
  }
  throw `no case match ${x._tag}`
}

const isOk = <A>(fa: Loadable<A>): fa is r.Ok<A> => fa._tag === 'Ok'

const isErr = <A>(fa: Loadable<A>): fa is r.Err => fa._tag === 'Err'

const isLoading = <A>(fa: Loadable<A>): fa is Loading => fa._tag === 'Loading'

const isNotRequested = <A>(fa: Loadable<A>): fa is NotRequested =>
  fa._tag === 'NotRequested'

type CaseMatchWithoutBar<A, B> = r.CaseMatch<A, B> & {
  Loading: () => B
  NotRequested: () => B
}

export type CaseMatch<A, B> =
  | (CaseMatchWithoutBar<A, B> & { _?: () => B })
  | (Partial<CaseMatchWithoutBar<A, B>> & { _: () => B })

declare module 'fp-ts/lib/HKT' {
  interface URItoKind<A> {
    Loadable: Loadable<A>
  }
}

const loadableM: monad.Monad1<URI | r.URI> &
  functor.Functor1<URI | r.URI> & { caseOf: typeof caseOf } = {
  URI,
  of,
  ap,
  map,
  chain,
  caseOf,
}

export const loadable = {
  ...pipeable(loadableM),
  isOk,
  isErr,
  isLoading,
  isNotRequested,
  getEq,
  getShow,
  getMonoid,
  loadable: loadableM,
  getOrElse:
    <A>(onNone: () => A): ((ma: Loadable<A>) => A) =>
    (ma: Loadable<A>) =>
      loadableM.caseOf(ma, { Ok: identity, _: onNone }),
  caseOf:
    <A, B>(cases: CaseMatch<A, B>) =>
    (ma: Loadable<A>) =>
      loadableM.caseOf(ma, cases),
  toOption: <A>(ma: Loadable<A>): Option<A> =>
    loadableM.caseOf(ma, { Ok: option.some, _: () => option.none }),
  lookup:
    (key: string) =>
    <A>(
      rec: Loadable<Record<string, A>> | Record<string, Loadable<A>>
    ): Loadable<A> =>
      LoadableC(t.any).is(rec)
        ? loadableM.chain(rec, (rec) =>
            pipe(
              record.lookup(key, rec),
              option.fold<A, Loadable<A>>(() => NotRequested, r.Ok)
            )
          )
        : pipe(
            record.lookup(key, rec),
            option.getOrElse<Loadable<A>>(() => NotRequested)
          ),
}
