import * as React from 'react'
import { FailableAction, Action, actionCreator } from '../types'
import { Actions as AllActions } from '../state/store'
import { TranslatedMessage } from '../translations/data'

export type FailableActions = Extract<AllActions, FailableAction<any, any>>
type FailableActionTypes = FailableActions['type']

export type QueueItem = [FailableActionTypes, 'success' | 'error']

export type State = QueueItem[]
export type CloseNotification = Action<'close_notification'>
export const closeNotification =
  actionCreator<CloseNotification>('close_notification')

export type Actions = CloseNotification

type ActionsLookup = Record<
  FailableActionTypes,
  {
    success?: React.ReactNode
    error?: React.ReactNode
  }
>

export const lookup: ActionsLookup = {
  /* eslint-disable @typescript-eslint/naming-convention */
  'ACCOUNT/create_organization_result': {},
  'ACCOUNT/post_organization_users_result': {},
  'ACCOUNT/put_organization_users_result': {},
  'WALLETS/GET_WALLETS_RESULT': {},
  '@react-toolkit/get_current_workspace_result': {},
  '@react-toolkit/get_account_result': {},
  get_info_result: {},
  'ACCOUNT/login_result': {},
  '@react-toolkit/logout_result': {},
  'ACCOUNT/update_result': {
    error: <TranslatedMessage id="ACCOUNT/update_result_RESULT_ERROR" />,
    success: <TranslatedMessage id="ACCOUNT/update_result_RESULT_SUCCESS" />,
  },
  'ACCOUNT/delete_organization_users_result': {},
  'ACCOUNT/get_organization_users_result': {},
  'ACCOUNT/change_password_result': {},
  'ACCOUNT/sign_up_result': {},
  'ORGANIZATION/update_organization_modules_result': {},
  'ORGANIZATION/update_organization_result': {
    error: (
      <TranslatedMessage id="ACCOUNT/update_organization_result_RESULT_ERROR" />
    ),
    success: (
      <TranslatedMessage id="ACCOUNT/update_organization_result_RESULT_SUCCESS" />
    ),
  },
  'ACCOUNT/upload_image_result': {},
  'ACCOUNT/get_balance_trigger_result': {
    error: <TranslatedMessage id="ACCOUNT/get_balance_trigger_result_error" />,
  },
  'ACCOUNT/add_balance_trigger_result': {
    error: <TranslatedMessage id="ACCOUNT/add_balance_trigger_result_error" />,
    success: (
      <TranslatedMessage id="ACCOUNT/add_balance_trigger_result_success" />
    ),
  },
  'ACCOUNT/update_balance_trigger_result': {
    error: (
      <TranslatedMessage id="ACCOUNT/update_balance_trigger_result_error" />
    ),
    success: (
      <TranslatedMessage id="ACCOUNT/update_balance_trigger_result_success" />
    ),
  },
  'ACCOUNT/delete_balance_trigger_result': {
    error: (
      <TranslatedMessage id="ACCOUNT/delete_balance_trigger_result_error" />
    ),
    success: (
      <TranslatedMessage id="ACCOUNT/delete_balance_trigger_result_success" />
    ),
  },

  'ACCOUNT/add_phonenumber_result': {
    error: <TranslatedMessage id="ACCOUNT/add_phonenumber_result_error" />,
    success: <TranslatedMessage id="ACCOUNT/add_phonenumber_result_success" />,
  },
  'ACCOUNT/verify_phonenumber_result': {
    error: <TranslatedMessage id="ACCOUNT/verify_phonenumber_result_error" />,
    success: (
      <TranslatedMessage id="ACCOUNT/verify_phonenumber_result_success" />
    ),
  },
  'AMAPPROVAL/UPSERT_CONFIG_RESULT': {
    error: <TranslatedMessage id="AMAPPROVAL/upsert_config_result_error" />,
  },
  /* eslint-enable @typescript-eslint/naming-convention */
}
