import { Col, Row, Spin, Typography } from 'antd'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import logo from '../.../../../assets/logo.png'
import { State } from '../../shared/state/store'
import { TranslatedMessage } from '../../shared/translations/data'
import { loadable } from '../../shared/types'
import Login from './Login'
import ResetPassword from './ResetPassword'
import Signup from './SignUp/SignUpModal'
import TFAEmail from './TFAEmail'
import Activation from './Activation'

const LandingLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  grid-template-rows: auto;
`

const SpinOverlay = styled(
  (
    p: React.ComponentProps<typeof Spin> & {
      className?: string
    }
  ) => <Spin {...p} wrapperClassName={p.className} />
)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  *.ant-spin {
    max-height: 100% !important;
  }
  *.ant-spin-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`

const LandingHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex start;
  margin-top: auto;

  img {
    height: 64px;
    margin: 0 auto 8px;
  }
  div {
    font-size: 20px;
  }
`

const LandingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const LandingFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin-bottom: auto;
`

const Authentication = () => {
  const loading = useSelector(
    (s: State) =>
      loadable.isLoading(s.account.loginResult) ||
      loadable.isLoading(s.prosperus.account) ||
      loadable.isLoading(s.account.signUpResult)
  )

  return (
    <LandingLayout>
      {loading && <SpinOverlay />}
      <LandingHeader>
        <img src={logo} />
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          INVOICE FINANCING
        </Typography.Paragraph>
      </LandingHeader>
      <LandingContent>
        <Switch>
          <Route path="/auth/activate" component={Activation} />
          <Route path="/auth/reset" component={ResetPassword} />
          <Route path="/auth/request-tfa" component={TFAEmail} />
          <Route path="/auth/signup" component={Signup} />
          <Route path="/auth/login" component={Login} />
        </Switch>
      </LandingContent>

      <LandingFooter>
        <Row gutter={[8, 32]}>
          <Col lg={8} sm={24} style={{ textAlign: 'center' }}>
            <a href="https://app.prosperus.tech/termsAndConditions">
              <Typography.Text>
                <TranslatedMessage id="TermsOfUse" />
              </Typography.Text>
            </a>
          </Col>
          <Col lg={8} sm={24} style={{ textAlign: 'center' }}>
            <a href="https://www.prosperus.tech/">
              <Typography.Text>
                <TranslatedMessage id="Support" />
              </Typography.Text>
            </a>
          </Col>
          <Col lg={8} sm={24} style={{ textAlign: 'center' }}>
            <a href="https://www.prosperus.tech/">
              <Typography.Text>
                <TranslatedMessage id="Contact" />
              </Typography.Text>
            </a>
          </Col>
        </Row>
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          <TranslatedMessage id="CopyRightMessage" />
        </Typography.Paragraph>
      </LandingFooter>
    </LandingLayout>
  )
}

export default Authentication
