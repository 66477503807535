import { Locale } from './../translations/data'
import * as effects from 'redux-saga/effects'
import {
  PayloadAction,
  payloadActionCreator,
  loadable,
  result,
} from './../types'
import { array, option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import { UpdateAccountResult } from '../../components/Account'
import { UAAAccount, GetAccountResultAction } from '@library/react-toolkit'

export type State = Locale

export const initialState: State = pipe(
  [...navigator.languages],
  array.findFirst(Locale.is),
  option.getOrElse<Locale>(() => 'en')
)

export type SetLocaleAction = PayloadAction<'set_locale', Locale>
export const setLocaleAction =
  payloadActionCreator<SetLocaleAction>('set_locale')

export type Actions = SetLocaleAction | GetAccountResultAction

export const reducer = (s = initialState, a: Actions): State => {
  switch (a.type) {
    case 'set_locale':
      return a.payload
    case '@react-toolkit/get_account_result':
      console.log(a.payload)
      return pipe(
        a.payload,
        loadable.toOption,
        option.chain((a) => a.langKey),
        option.chain(option.fromPredicate(Locale.is)),
        option.getOrElse(() => s)
      )
    default:
      return s
  }
}
const language: 'en' | 'fr' =
  navigator.language?.trim()?.split(/-|_/)[0] === 'en' ? 'en' : 'fr'

function* updateLocaleAction(a: GetAccountResultAction | UpdateAccountResult) {
  yield pipe(
    a.payload,
    result.caseOf<UAAAccount, option.Option<string>>({
      Ok: (p) => p.langKey,
      Err: () => option.none,
    }),
    option.fold(
      () => [effects.put(setLocaleAction(language))],
      (l) => [
        effects.put(
          setLocaleAction(l === 'en' ? 'en' : l === 'fr' ? 'fr' : language)
        ),
      ]
    ),
    (es) => effects.all(es)
  )
}

export function* saga() {
  yield effects.all([
    effects.takeEvery(
      ['ACCOUNT/update_result', 'ACCOUNT/get_account_result'],
      updateLocaleAction
    ),
  ])
}
