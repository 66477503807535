import { pipe } from 'fp-ts/lib/pipeable'
import { either, ioEither, io } from 'fp-ts'
import * as t from 'io-ts'
import { flow } from 'fp-ts/lib/function'
import { failure } from 'io-ts/lib/PathReporter'

const validationErrToErr = (es: t.ValidationError[]) => failure(es).join('\n')

const decode = <A>(decoder: t.Decoder<unknown, A>) =>
  flow(decoder.decode, either.mapLeft(validationErrToErr))

const JSONParse = (orElse: (_: unknown) => string) => (x: string) =>
  either.tryCatch(() => JSON.parse(x), orElse)

export const getItem = <A>(
  key: string,
  decoder: t.Decoder<unknown, A>
): ioEither.IOEither<string, A> =>
  pipe(
    ioEither.tryCatch(
      () => localStorage.getItem(key),
      (e) => `could not read '${key}': ${e}`
    ),
    io.map(either.chain(either.fromNullable(`${key} not found`))),
    io.map(
      either.chain(JSONParse((err) => `could not parse key '${key}': ${err}`))
    ),
    io.map(either.chain(decode(decoder)))
  )

export const setItem = <A>(
  key: string,
  value: A,
  encoder: t.Encoder<A, any>
): ioEither.IOEither<string, void> =>
  ioEither.tryCatch(
    () => localStorage.setItem(key, JSON.stringify(encoder.encode(value))),
    (e) => `could not set '${key}': ${e}`
  )

export const deleteItem =
  (key: string): io.IO<void> =>
  () =>
    localStorage.removeItem(key)
