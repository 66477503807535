import * as t from 'io-ts'
import { array, option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import { Action } from 'redux'
import {
  actionCreator,
  FailableAction,
  failableActionCreator,
  Memoed,
} from './../../types'

export type State = Memoed<Record<string, UnitInfo>>

export const Unit = t.type(
  {
    format: t.array(t.string),
    name: t.string,
  },
  'Unit'
)

export type Unit = t.TypeOf<typeof Unit>

export const Units = t.record(t.string, Unit, 'Units')
export type Units = t.TypeOf<typeof Unit>

export type UnitInfo = {
  name: string
  symbol: string
  fractionDigits: number
  divider: number
}

const formatRegex = /-1(0*) ([^ ]+)/

export const unitToUnitInfo = (u: Unit) => {
  const matchedFormat = pipe(
    array.head(u.format),
    option.map((f) => formatRegex.exec(f)),
    option.chain(option.fromNullable)
  )
  const symbol = pipe(
    matchedFormat,
    option.chain(array.lookup(2)),
    option.getOrElse(() => u.name)
  )
  const zeros = pipe(matchedFormat, option.chain(array.lookup(1)))
  const fractionDigits = pipe(
    zeros,
    option.map((x) => x.length),
    option.getOrElse(() => 0)
  )
  const divider = pipe(
    zeros,
    option.map((x) => 10 ** x.length),
    option.getOrElse(() => 0)
  )
  return { name: u.name, symbol, fractionDigits, divider }
}

export type GetUnitsAction = Action<'UNITS/get_units'>
export const getUnitsAction = actionCreator<GetUnitsAction>('UNITS/get_units')

export type GetUnitsResult = FailableAction<
  'UNITS/get_units_result',
  Record<string, UnitInfo>
>
export const getUnitsResult = failableActionCreator<GetUnitsResult>(
  'UNITS/get_units_result'
)

export type Actions = GetUnitsAction | GetUnitsResult
