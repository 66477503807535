import { useAccount } from '@library/react-toolkit'
import { Button, Col, Row, Switch, Typography } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import { updateAccountAction } from '../types'

const { Title } = Typography

const Twofactor = styled(Title)`
  color: #51b148 !important;

  font-size: 21px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 2px;
`

const Save = styled(Button)`
  width: 251px;
  height: 32px;
`

const TwoFactorSwitch = () => {
  const account = useAccount()
  const check = account ? account?.tfaEnabled : false
  const [switchCheck, setSwitchCheck] = React.useState(check)
  const updateLoading = useSelector((s: State) =>
    loadable.isLoading(s.accountSetting.updateResult)
  )
  const dispatch = useDispatch()

  return (
    <>
      <Row gutter={[40, 30]}>
        <Col>
          <Twofactor level={4}>
            <TranslatedMessage id="twoFactor" />
          </Twofactor>
        </Col>
      </Row>
      <Row gutter={[40, 30]}>
        <Col span={8}>
          <TranslatedMessage id="enableTwoFactor" />
        </Col>
        <Col span={6}>
          <Switch
            loading={updateLoading}
            checked={switchCheck}
            onChange={setSwitchCheck}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Save
            type="primary"
            onClick={() => {
              dispatch(updateAccountAction({ tfaEnabled: switchCheck }))
            }}
          >
            <TranslatedMessage id="save" />
          </Save>
        </Col>
      </Row>
    </>
  )
}
export default TwoFactorSwitch
