import { EditOutlined } from '@ant-design/icons'
import {
  OrganizationUser,
  useCurrentWorkspace,
  Module,
  applicationDefinitions,
} from '@library/react-toolkit'
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Switch,
} from 'antd'
import { array, option, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../shared/state/store'

import { TranslatedMessage } from '../../../../shared/translations/data'
import { loadable } from '../../../../shared/types'
import { applications, putOrganizationUsers } from '../../types'
import { fromWalletsPermission, toUserPermissions } from './forms'
import SelectWalletPermission from './SelectWalletPermission'

const images: Record<Module, React.ReactNode> = pipe(
  applicationDefinitions,
  record.map((v) => v.icon)
)

type OwnProps = { user: OrganizationUser }

type Props = OwnProps

type OrganizationUserWithJobTitle = Omit<OrganizationUser, 'jobTitle'> & {
  jobTitle?: string
}

const UserEditModal = ({ user }: Props) => {
  const [visible, setVisible] = React.useState(false)
  const [form] = Form.useForm()
  const ws = useCurrentWorkspace()
  const loading = useSelector((s: State) =>
    loadable.isLoading(s.accountSetting.organizationUpdateResult)
  )
  const permissions = ws?.type === 'organization' ? ws.organization.modules : []
  const dispatch = useDispatch()

  return (
    <>
      <Button icon={<EditOutlined />} onClick={() => setVisible(true)} />
      <Modal
        width={700}
        title={
          <TranslatedMessage
            id="EditOrganizationUser"
            values={{ login: user.login }}
          />
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            form="userEditForm"
            key="close"
            onClick={() => setVisible(false)}
          >
            <TranslatedMessage id="cancel" />
          </Button>,
          <Button
            type="primary"
            form="userEditForm"
            key="submit"
            htmlType="submit"
            loading={loading}
          >
            <TranslatedMessage id="save" />
          </Button>,
        ]}
      >
        <Form<OrganizationUserWithJobTitle>
          name="userEditForm"
          form={form}
          size="small"
          labelAlign="left"
          labelCol={{ span: 8 }}
          initialValues={{
            ...user.modulePermissions,
            walletPermissions: fromWalletsPermission(user.walletPermissions),
            admin: user.authority === 'ADMIN',
            jobTitle: option.toUndefined(user.jobTitle),
          }}
          onFinish={(values) =>
            dispatch(
              putOrganizationUsers(
                toUserPermissions(user.login, {
                  ...values,
                  jobTitle: !!values.jobTitle ? values.jobTitle : undefined,
                })
              )
            )
          }
        >
          <Form.Item
            label={<TranslatedMessage id="Permissions" />}
            labelCol={{ span: 24 }}
          >
            {pipe(
              applications,
              array.filter((app) => permissions.includes(app.name)),
              array.map((app) => (
                <Row key={app.descriptionKey} justify="end">
                  <Col span={2}>
                    <Avatar icon={images[app.name]} />
                  </Col>
                  <Col span={6}>
                    <TranslatedMessage id={app.name} />
                  </Col>
                  <Col span={14}>
                    <Form.Item name={app.name}>
                      <Radio.Group>
                        <Radio.Button value="CONTRIBUTOR">
                          <TranslatedMessage id="Contributor" />
                        </Radio.Button>
                        <Radio.Button value="VIEWER">
                          <TranslatedMessage id="Viewer" />
                        </Radio.Button>
                        <Radio.Button value="NONE">
                          <TranslatedMessage id="None" />
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ))
            )}
          </Form.Item>
          <Form.Item
            label={<TranslatedMessage id="walletPermissions" />}
            name="walletPermissions"
            labelCol={{ span: 24 }}
          >
            <SelectWalletPermission
              form={form}
              walletPermissions={fromWalletsPermission(user.walletPermissions)}
            />
          </Form.Item>
          <Form.Item
            label={<TranslatedMessage id="jobTitle" />}
            name="jobTitle"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="requiredJobTitle" />,
              },
              {
                min: 1,
                message: <TranslatedMessage id="requiredJobTitle" />,
              },
            ]}
            labelCol={{ span: 24 }}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name="admin"
            label={<TranslatedMessage id="ROLE_ADMIN" />}
            valuePropName="checked"
          >
            <Switch size="default" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default UserEditModal
