import React from 'react'
import {
  Locale,
  translatedMessage,
  TranslatedMessage,
} from '../../../../shared/translations/data'
import SignUpStep, { Props as StepProps } from '../SignUpStep'
import { SignUp, SignUp as SignUpData } from '../../Login'
import { Form, Input } from 'antd'
import { verifyOrganizationNameExists } from '../../Login/api'
import { either } from 'fp-ts'
import { FormattedMessage } from 'react-intl'

type FormProps<T extends Partial<SignUp>> = StepProps<T> & {
  locale: Locale
  goBack?: () => void
}

type UserOrganization = Pick<SignUpData, 'organization'>

function OrganizationInfoStep<T extends UserOrganization>({
  locale,
  goBack,
  ...stepProps
}: FormProps<T>) {
  return (
    <SignUpStep<T> {...stepProps}>
      <Form.Item
        label={<FormattedMessage id="organizationName" />}
        name={['organization', 'name']}
        rules={[
          {
            pattern: new RegExp(/^[\w]+$/),
            message: <TranslatedMessage id="onlyAlphanumeric" />,
          },
          {
            required: true,
            message: <TranslatedMessage id="missingCompanyNameMsg" />,
          },
          {
            message: <TranslatedMessage id="OrganizationNameExist" />,
            validator: (_, v) =>
              verifyOrganizationNameExists(v)().then(
                either.fold(
                  () => {},
                  () => {
                    throw 'exists'
                  }
                )
              ),
          },
        ]}
      >
        <Input
          allowClear
          placeholder={translatedMessage(locale)('organizationName')}
        />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="crNumber" />}
        name={['organization', 'crNumber']}
        rules={[
          {
            required: true,
            pattern: new RegExp(/^[0-9]{10,10}$/),
            message: <TranslatedMessage id="missingCRNumberMsg" />,
          },
        ]}
      >
        <Input allowClear placeholder="1234567890" />
      </Form.Item>
    </SignUpStep>
  )
}

export default OrganizationInfoStep
