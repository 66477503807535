import { UserAddOutlined } from '@ant-design/icons'
import {
  applicationDefinitions,
  useCurrentWorkspace,
} from '@library/react-toolkit'
import {
  Alert,
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Switch,
} from 'antd'
import { array, either, option, task, taskEither } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { TranslatedMessage } from '../../../../shared/translations/data'
import { verifyUserExists } from '../../../Authentication/Login/api'
import { applications, postOrganizationUser } from '../../types'
import { toOrganizationUser, toUserPermissions } from './forms'
import SelectWalletPermission from './SelectWalletPermission'

function firstRight<L, R>(xs: either.Either<L, R>[]): option.Option<R> {
  for (const x of xs) {
    if (either.isRight(x)) {
      return option.some(x.right)
    }
  }

  return option.none
}

type ExistsResponse = { email: string; login: string }

const UserAddModal = () => {
  // TODO: fix user checking
  const [visible, setVisible] = React.useState(false)

  const [useExistingUser, setUserExistingUser] = React.useState<
    string | undefined
  >(undefined)

  const [login, setLogin] = React.useState('')

  const [email, setEmail] = React.useState('')

  const [form] = Form.useForm()

  const ws = useCurrentWorkspace()

  const permissions = ws?.type === 'organization' ? ws.organization.modules : []

  const dispatch = useDispatch()

  const [userExists, setUserExists] = React.useState<
    option.Option<ExistsResponse>
  >(option.none)

  React.useEffect(() => {
    if (visible) {
      form.resetFields()
      setLogin('')
      setEmail('')
      setUserExistingUser(undefined)
    }
  }, [visible])

  React.useEffect(() => {
    const ts: taskEither.TaskEither<Error, ExistsResponse>[] = []

    if (!!email) {
      ts.push(verifyUserExists(email))
    }

    if (!!login) {
      ts.push(verifyUserExists(login))
    }

    pipe(
      ts,
      array.sequence(task.MonadTask),
      task.map(firstRight),
      task.map(setUserExists)
    )()
  }, [login, email])

  return (
    <>
      <Button icon={<UserAddOutlined />} onClick={() => setVisible(true)}>
        <TranslatedMessage id="AddUser" />
      </Button>
      <Modal
        width={700}
        title={<TranslatedMessage id="AddOrganizationUser" />}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            form="userAddForm"
            key="close"
            onClick={() => setVisible(false)}
          >
            <TranslatedMessage id="cancel" />
          </Button>,
          <Button
            type="primary"
            form="userAddForm"
            key="submit"
            htmlType="submit"
          >
            <TranslatedMessage id="add" />
          </Button>,
        ]}
      >
        <Form
          id="userAddForm"
          form={form}
          size="small"
          labelAlign="left"
          labelCol={{ span: 8 }}
          onValuesChange={(values: Record<string, string>) => {
            if (!!values.email) {
              setEmail(values.email)
            }
            if (!!values.login) {
              setLogin(values.login)
            }
          }}
          onFinish={(values) => {
            dispatch(
              postOrganizationUser(
                useExistingUser === undefined
                  ? {
                      type: 'new',
                      user: {
                        ...toOrganizationUser(values),
                        langKey: option.none,
                        imageUrl: option.none,
                        jobTitle: option.fromNullable(values.jobTitle),
                        activated: true,
                      },
                      appName: 'samoon',
                    }
                  : {
                      type: 'existing',
                      user: toUserPermissions(useExistingUser, values),
                    }
              )
            )

            setVisible(false)
          }}
        >
          {!useExistingUser ? (
            <>
              <Form.Item
                label={<TranslatedMessage id="email" />}
                name="email"
                required
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: <TranslatedMessage id="missingEmailMsg" />,
                  },
                ]}
                tooltip="This is a required field"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                label={<TranslatedMessage id="login" />}
                name="login"
                required
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="missingUsernameMsg" />,
                  },
                ]}
                tooltip="This is a required field"
              >
                <Input placeholder="Login" />
              </Form.Item>
              <Form.Item
                label={<TranslatedMessage id="fullName" />}
                required
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="missingFullNameMsg" />,
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Row justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      tooltip="This is a required field"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: (
                            <TranslatedMessage id="missingFirstnameMsg" />
                          ),
                        },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      tooltip="This is a required field"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: (
                            <TranslatedMessage id="missingLastnameMsg" />
                          ),
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              {pipe(
                userExists,
                option.fold(
                  (): React.ReactNode => null,
                  (e) => (
                    <Alert
                      message={
                        <TranslatedMessage id="addUserExists" values={e} />
                      }
                      closeText={<TranslatedMessage id="accept" />}
                      onClose={() => setUserExistingUser(e.login)}
                      type="warning"
                    />
                  )
                )
              )}
            </>
          ) : (
            <TranslatedMessage
              id="addingExistingUser"
              values={{ login: useExistingUser }}
            />
          )}
          <br />
          <Form.Item
            label={<TranslatedMessage id="jobTitle" />}
            name="jobTitle"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="requiredJobTitle" />,
              },
              {
                min: 1,
                message: <TranslatedMessage id="requiredJobTitle" />,
              },
            ]}
            labelCol={{ span: 24 }}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label={<TranslatedMessage id="Permissions" />}
            labelCol={{ span: 24 }}
          >
            {pipe(
              applications,
              array.filter((app) => permissions.includes(app.name)),
              array.map((app) => (
                <Row key={app.descriptionKey} justify="end">
                  <Col span={2}>
                    <Avatar icon={applicationDefinitions[app.name].icon} />
                  </Col>
                  <Col span={6}>
                    <TranslatedMessage id={app.name} />
                  </Col>
                  <Col span={14}>
                    <Form.Item name={app.name}>
                      <Radio.Group>
                        <Radio.Button value="CONTRIBUTOR">
                          <TranslatedMessage id="Contributor" />
                        </Radio.Button>
                        <Radio.Button value="VIEWER">
                          <TranslatedMessage id="Viewer" />
                        </Radio.Button>
                        <Radio.Button value="NONE">
                          <TranslatedMessage id="None" />
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ))
            )}
          </Form.Item>
          <Form.Item
            label="Wallet permissions"
            name="walletPermissions"
            labelCol={{ span: 24 }}
          >
            <SelectWalletPermission form={form} />
          </Form.Item>
          <Form.Item
            name="admin"
            label={<TranslatedMessage id="ROLE_ADMIN" />}
            valuePropName="checked"
          >
            <Switch checked={false} size="default" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default UserAddModal
