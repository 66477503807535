import * as t from './types'
import * as api from './api'
import * as effects from 'redux-saga/effects'
import { NotRequested, Loading, result } from '../../../shared/types'
import { pipe } from 'fp-ts/lib/function'
import { lens } from 'lens.ts'
import { CancelToken } from 'axios'
import { takeLatest } from '../../../shared/state/saga'
import { task } from 'fp-ts'
import { push } from 'connected-react-router'
import { foldEitherToAction } from '@library/react-toolkit'

export const initialState: t.State = {
  requestResult: NotRequested,
}

const _l = lens<t.State>()

export const reducer = (s = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'TFA/request':
      return pipe(s, _l.requestResult.set(Loading))
    case 'TFA/request_result':
      return pipe(s, _l.requestResult.set(a.payload))
    case '@@router/LOCATION_CHANGE':
      return initialState
    default:
      return s
  }
}

function* requestTFASaga(
  a: t.RequestTFAAction,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  yield yield effects.call(
    pipe(
      api.requestTFACode(a.payload.email, token),
      task.map(foldEitherToAction(t.requestTFResult)),
      task.map((a) => effects.put(a))
    )
  )
}

function* requestResultSaga(a: t.RequestTFAResult) {
  if (result.isErr(a.payload)) {
    return
  }
  yield effects.delay(5000)
  yield effects.put(push('/'))
}

export function* saga() {
  yield effects.all([
    takeLatest('TFA/request', requestTFASaga),
    takeLatest('TFA/request_result', requestResultSaga),
  ])
}
