import {
  GetAccountResultAction,
  LogoutResultAction,
} from '@library/react-toolkit'
import { pipe } from 'fp-ts/lib/pipeable'
import * as effects from 'redux-saga/effects'

import * as info from './Info'
import { deleteItem, setItem } from './io'
import { takeLatest } from './saga'
import { State } from './store'

function* saveInfoSaga(_: info.GetInfoResult) {
  const i: State['info'] = yield effects.select(({ info }: State) => info)
  setItem('info', i, info.StateC)()
}

function* clearStateSaga(a: LogoutResultAction | GetAccountResultAction) {
  if (
    a.type === '@react-toolkit/get_account_result' &&
    a.payload._tag === 'Ok'
  ) {
    return
  }
  pipe(deleteItem('currentWorkspace'))()
}

export function* saga() {
  yield effects.all([
    effects.throttle(5000, 'get_info_result', saveInfoSaga),
    takeLatest(
      ['@react-toolkit/logout_result', '@react-toolkit/get_account_result'],
      clearStateSaga
    ),
  ])
}
