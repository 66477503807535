import {
  useAccount,
  useCurrentWorkspace,
  useOrganizationPermissions,
  Module,
} from '@library/react-toolkit'
import { Alert, Card, Divider, Typography } from 'antd'
import { array } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import styled from 'styled-components'

import { TranslatedMessage } from '../../shared/translations/data'
import { applications } from './types'

const { Meta } = Card
const CardContainer = styled(Card)`
  padding-top: 24px;
  height: 100%;
  transition: box-shadow 0.3s, border-color 0.3s, transform 0.3s;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);
`

const Icon = styled.div`
  height: 160px;
  width: 165px;
  background: #fbbc05;
  border-radius: 80px;
  margin: auto;
  svg {
    height: 80px;
    width: 80px;
    color: #fff;
    margin-left: 40px;
    margin-top: 40px;
  }
`

const MetaContainer = styled(Meta)`
  display: block;
  text-align: center;
`

const CardHover = styled.a`
  &:hover {
    ${CardContainer} {
      height: 100%;
      transform: scale(1.01);
      box-shadow: 0 16px 16px rgba(0, 0, 0, 0.4), 0 24px 24px rgba(0, 0, 0, 0.1);
    }
  }
`

const ModulesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 20rem));
  grid-gap: 16px;
  justify-content: center;
`

export const useIsSmartHubAdmin = () => {
  const org = useOrganizationPermissions()
  const ws = useCurrentWorkspace()
  return (
    ws?.type === 'organization' &&
    (ws.organization.name === 'SmartHub' ||
      ws.organization.name === 'PROSPERUS') &&
    org?.authority === 'ADMIN'
  )
}

const BillsAdminCard = () => {
  return (
    <CardHover key="BILLS_ADMIN" href="/bills-admin-app">
      <CardContainer
        hoverable
        onClick={() => {
          window.location.href = '/bills-admin-app'
        }}
        bordered={false}
        cover={
          <Icon>
            {
              <span className="anticon">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.35813 1.81874C3.49981 0.805853 4.97377 0.247588 6.5 0.249992C9.95187 0.249992 12.75 3.04812 12.75 6.49999C12.752 7.77743 12.3608 9.02453 11.6294 10.0719L9.9375 6.49999H11.5C11.5 5.50584 11.2037 4.53425 10.6488 3.70934C10.094 2.88442 9.30581 2.24364 8.38502 1.86884C7.46422 1.49404 6.4526 1.40225 5.47938 1.60518C4.50616 1.80811 3.61552 2.29655 2.92125 3.00812L2.35875 1.81937L2.35813 1.81874ZM10.6419 11.1812C9.50019 12.1941 8.02623 12.7524 6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.49999C0.25 5.17187 0.664375 3.94062 1.37063 2.92812L3.0625 6.49999H1.5C1.49998 7.49415 1.79633 8.46574 2.35117 9.29065C2.90602 10.1156 3.69419 10.7563 4.61498 11.1311C5.53578 11.5059 6.5474 11.5977 7.52062 11.3948C8.49384 11.1919 9.38448 10.7034 10.0787 9.99187L10.6412 11.1806L10.6419 11.1812ZM7.38375 8.26749L5.61625 6.49999L3.84812 8.26749L2.96438 7.38374L5.61625 4.73249L7.38375 6.49999L9.15187 4.73249L10.0356 5.61624L7.38375 8.26749Z"
                    fill="white"
                  />
                </svg>
              </span>
            }
          </Icon>
        }
      >
        <Divider />
        <MetaContainer
          title={
            <Typography.Title level={4} type="secondary">
              {<TranslatedMessage id="BILLS_ADMIN" />}
            </Typography.Title>
          }
          description={<TranslatedMessage id="BILLS_ADMIN-description" />}
        />
      </CardContainer>
    </CardHover>
  )
}

const AccountApps = () => {
  const ws = useCurrentWorkspace()
  const account = useAccount()
  const isSmartHubAdmin = useIsSmartHubAdmin()

  console.log(isSmartHubAdmin)
  const hasApplication = (app: Module) => {
    if (!ws || !account) {
      return false
    } else if (ws.type === 'personal') {
      return app === 'WEB_WALLET'
    } else {
      return !!account.organizations[ws.organization.name]?.modulePermissions?.[
        app
      ]
    }
  }

  const availableApplications = pipe(
    applications,
    array.filter((app) => hasApplication(app.name))
  )

  React.useEffect(() => {
    if (availableApplications.length === 1) {
      window.location.href = availableApplications[0].href
    }
  }, [availableApplications.length])

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: 'center' }}>
        <TranslatedMessage id="services" />
      </Typography.Title>
      <Divider />
      {applications.length === 0 ? (
        <Alert
          message={<TranslatedMessage id="noAuthority" />}
          description={<TranslatedMessage id="noModulePermissions" />}
          type="warning"
        />
      ) : (
        <ModulesGrid>
          {availableApplications.map((app) => (
            <CardHover key={app.name} href={app.href}>
              <CardContainer
                hoverable
                onClick={() => {
                  window.location.href = app.href
                }}
                bordered={false}
                cover={<Icon>{app.icon}</Icon>}
              >
                <Divider />
                <MetaContainer
                  title={
                    <Typography.Title level={4} type="secondary">
                      {<TranslatedMessage id={app.name} />}
                    </Typography.Title>
                  }
                  description={<TranslatedMessage id={app.descriptionKey} />}
                />
              </CardContainer>
            </CardHover>
          ))}
          {isSmartHubAdmin ? <BillsAdminCard /> : <></>}
        </ModulesGrid>
      )}
    </>
  )
}

export default AccountApps
