import { LeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { push } from 'connected-react-router'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { TranslatedMessage } from '../translations/data'

const Bar = styled.div`
  width: 100%;
  margin-bottom: 32px;
`

const BackHome = () => {
  const dispatch = useDispatch()

  return (
    <Bar>
      <Button icon={<LeftOutlined />} onClick={() => dispatch(push('/'))}>
        <TranslatedMessage id="backToApplicationSelect" />
      </Button>
    </Bar>
  )
}

export default BackHome
