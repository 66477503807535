import {
  Err,
  loadable,
  Loadable,
  Loading,
  NotRequested,
  Ok,
} from '@library/react-toolkit'
import { Button } from 'antd'
import { either, task } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import styled from 'styled-components'
import { TranslatedMessage } from '../../../shared/translations/data'
import { resendPhonenumberVerification } from '../api'

const ResendBlock = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.paddingUnit}px;
  justify-content: center;
`

const ResendSMSCode = ({ phonenumber = '' }) => {
  const [resetResult, setResetResult] =
    React.useState<Loadable<unknown>>(NotRequested)
  const [countdown, setCountdown] = React.useState(60)
  const decrement = () => {
    setCountdown((c) => {
      if (c > 1) {
        setTimeout(decrement, 1000)
      }
      return c - 1
    })
  }

  React.useEffect(() => {
    if (!!phonenumber) {
      decrement()
    } else {
      setCountdown(60)
    }
  }, [phonenumber])

  React.useEffect(() => {
    if (!phonenumber) {
      return
    }
    if (resetResult._tag === 'Ok') {
      setCountdown(60)
      decrement()
    }
  }, [resetResult._tag])

  return (
    <ResendBlock>
      {countdown > 0 ? (
        <TranslatedMessage id="resendSMSCodeCountdown" values={{ countdown }} />
      ) : (
        <TranslatedMessage id="resendSMSCodePrompt" />
      )}
      <Button
        disabled={countdown > 0}
        loading={loadable.isLoading(resetResult)}
        onClick={() => {
          setResetResult(Loading)
          pipe(
            resendPhonenumberVerification({
              phonenumber: phonenumber,
            }),
            task.map(
              either.fold(
                (e) => {
                  setResetResult(Err(e))
                },
                (v) => {
                  setResetResult(Ok(v))
                }
              )
            )
          )()
        }}
      >
        <TranslatedMessage id="resend" />
      </Button>
    </ResendBlock>
  )
}

export default ResendSMSCode
