import { Button, Form, Input, Modal, Steps, Typography } from 'antd'
import React from 'react'
import { TranslatedMessage } from '../../../shared/translations/data'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { PhonenumberRequest } from '../types'
import { useDispatch, useSelector } from 'react-redux'
import { addPhonenumber, verifyPhonenumber } from '..'
import { PhonenumberInput } from './PhonenumberInput'
import CountrySelect from './CountrySelect'
import styled from 'styled-components'
import ResendSMSCode from './ResendSMSCode'

const Paragraph = styled(Typography.Paragraph)`
  display: flex;
  justify-content: center;
  line-height: 1.5;
`

type Props = {
  phonenumber?: string
}

const ColoredSteps = styled(Steps)`
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  justifycontent: space-evenly;
  padding-bottom: ${(props) => props.theme.paddingUnit * 3}px;
`

const AddPhonenumberModal = ({ phonenumber }: Props) => {
  const addResult = useSelector(
    (s) => s.accountSetting.addPhonenumberResult._tag
  )

  const verificationResult = useSelector(
    (s) => s.accountSetting.verifyPhonenumberResult._tag
  )

  const [phonenumberForm] = Form.useForm<PhonenumberRequest>()
  const [step, setStep] = React.useState(0)
  const [visible, setVisible] = React.useState(false)
  const [value, setValue] = React.useState<string>()
  const [addState, setAddState] =
    React.useState<typeof addResult>('NotRequested')
  const [verificationState, setVerificationState] =
    React.useState<typeof verificationResult>('NotRequested')

  const dispatch = useDispatch()
  React.useEffect(() => {
    if (addState === 'Loading' && addResult === 'Ok') {
      phonenumberForm.resetFields()
      setAddState('NotRequested')
      setStep(1)
    } else {
      setAddState(addResult)
    }
  }, [addState, addResult])

  React.useEffect(() => {
    if (verificationState === 'Loading' && verificationResult === 'Ok') {
      phonenumberForm.resetFields()
      setVerificationState('NotRequested')
      setVisible(false)
    } else {
      setVerificationState(verificationResult)
    }
  }, [verificationState, verificationResult])

  const openModal = () => {
    setVisible(true)
    setStep(0)
    phonenumberForm.resetFields()
    setAddState('NotRequested')
    setVerificationState('NotRequested')
  }
  return (
    <>
      {phonenumber === undefined ? (
        <Button type="primary" onClick={openModal}>
          <TranslatedMessage id="addPhonenumber" />
        </Button>
      ) : (
        <Typography.Paragraph>
          <span>{phonenumber}</span>
          <Button type="text" onClick={openModal}>
            <TranslatedMessage id="edit" />
          </Button>
        </Typography.Paragraph>
      )}
      <Modal
        title={<TranslatedMessage id="newPhonenumber" />}
        visible={visible}
        onCancel={() => {
          phonenumberForm.resetFields()
          setStep(0)
          setVerificationState('NotRequested')
          setAddState('NotRequested')
          setVisible(false)
        }}
        okButtonProps={{
          loading: addResult === 'Loading' || verificationResult === 'Loading',
        }}
        okType="primary"
        okText={<TranslatedMessage id={step === 0 ? 'next' : 'Submit'} />}
        onOk={() => phonenumberForm.submit()}
      >
        <ColoredSteps current={step}>
          <Steps.Step title="Phone Number" />
          <Steps.Step title="Verification" />
        </ColoredSteps>
        <Form
          id="notificationAddForm"
          form={phonenumberForm}
          size="small"
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={(val) => {
            if (step == 0) {
              dispatch(addPhonenumber(val))
            } else {
              dispatch(verifyPhonenumber(val))
            }
          }}
        >
          {step === 0 ? (
            <Form.Item
              id="phone"
              name="phonenumber"
              required
              dependencies={['phonenumber']}
              rules={[
                {
                  required: true,
                  message: <TranslatedMessage id="phonenumberRequired" />,
                },
                {
                  validator: (_, value) =>
                    isValidPhoneNumber(value)
                      ? Promise.resolve()
                      : Promise.reject('Invalid phone number'),
                },
              ]}
              label={<TranslatedMessage id="phonenumberLabel" />}
            >
              <PhoneInput
                // @ts-ignore types don't match actual component props
                inputComponent={PhonenumberInput}
                countrySelectComponent={CountrySelect}
                value={value ?? ''}
                defaultCountry="TN"
                country="TN"
                onChange={setValue}
              />
            </Form.Item>
          ) : (
            <Typography.Paragraph
              style={{ display: 'grid', justifyItems: 'center' }}
            >
              <Paragraph style={{ textAlign: 'center' }}>
                <TranslatedMessage id="smsSentMessage" />
                <br />
                <TranslatedMessage id="smsCodePrompt" />
              </Paragraph>
              <Form.Item
                name="code"
                required
                style={{ minWidth: '400px' }}
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="phonenumberRequired" />,
                  },
                ]}
                label={<TranslatedMessage id="verificationCode" />}
              >
                <Input allowClear placeholder="Code" />
              </Form.Item>
              <ResendSMSCode phonenumber={value} />
            </Typography.Paragraph>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default AddPhonenumberModal
