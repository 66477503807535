import { useLocation } from 'react-router'

export { Err, Result, Ok, result } from './Result'
export { Loading, NotRequested, Loadable, loadable } from './Loadable'
export { MemoedC, Memoed, memoed } from './Memoed'
export * from './Actions'
export * from './Codec'
export * from './Transaction'
export * from './Profile'
export * from './UserState'

export type ValueOf<T> = T[keyof T]

export type Unpacked<T> = U<U<U<U<U<U<T>>>>>>

export type U<T> = T extends (...args: any[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T

export const useSearchParams = (): Record<string, string | string[]> => {
  const l = useLocation()
  let obj: Record<string, string | string[]> = {}
  new URLSearchParams(l.search).forEach((v, k) => {
    const prev = obj[k]
    if (!!prev) {
      const vals = prev instanceof Array ? [...prev, v] : [prev, v]
      obj = { ...obj, [k]: vals }
    } else {
      obj = { ...obj, [k]: v }
    }
  })
  return obj
}
