import {
  foldEitherToAction,
  Organization,
  waitForWorkspace,
  getAccountAction,
  Workspace,
  memoed,
  loadable,
  Loading,
  NotRequested,
  result,
  waitForAccount,
  UAAAccount,
} from '@library/react-toolkit'
import { CancelToken } from 'axios'
import { array, either, option, task } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import { lens } from 'lens.ts'
import * as effects from 'redux-saga/effects'
import { takeEvery } from 'redux-saga/effects'

import { takeLatest } from '../../shared/state/saga'
import { State } from '../../shared/state/store'
import * as api from './api'
import * as t from './types'

export const initialState: t.State = {
  wallets: NotRequested,
  updateResult: NotRequested,
  changePasswordResult: NotRequested,
  organizationUsers: NotRequested,
  organizationUpdateResult: NotRequested,
  notificationModal: NotRequested,
  uploadImageResult: NotRequested,
  selectedFile: option.none,
  balanceTriggers: memoed.init({ data: [], count: 0 }),
  addBalanceTriggerResult: NotRequested,
  updateBalanceTriggerResult: NotRequested,
  deleteBalanceTriggerResult: NotRequested,
  addPhonenumberResult: NotRequested,
  verifyPhonenumberResult: NotRequested,
}

const _l = lens<t.State>()

export const reducer = (s = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'ACCOUNT/update_selected_file':
      return pipe(s, _l.selectedFile.set(option.some(a.payload)))
    case 'ACCOUNT/update':
      return pipe(s, _l.updateResult.set(Loading))
    case 'ACCOUNT/update_result':
      return pipe(s, _l.updateResult.set(a.payload))
    case 'ACCOUNT/change_password':
      return pipe(s, _l.changePasswordResult.set(Loading))
    case 'ACCOUNT/change_password_result':
      return pipe(s, _l.changePasswordResult.set(a.payload))
    case 'ACCOUNT/get_organization_users':
      return pipe(s, _l.organizationUsers.set(Loading))
    case 'ACCOUNT/get_organization_users_result':
      console.log(a.payload)
      return pipe(s, _l.organizationUsers.set(a.payload))
    case 'ACCOUNT/post_organization_users':
      return pipe(s, _l.organizationUpdateResult.set(Loading))
    case 'ACCOUNT/post_organization_users_result':
      return pipe(s, _l.organizationUpdateResult.set(a.payload))
    case 'ACCOUNT/put_organization_users':
      return pipe(s, _l.organizationUpdateResult.set(Loading))
    case 'ACCOUNT/put_organization_users_result':
      console.log(a.payload)
      return pipe(s, _l.organizationUpdateResult.set(a.payload))
    case 'ACCOUNT/delete_organization_users':
      return pipe(s, _l.organizationUpdateResult.set(Loading))
    case 'ACCOUNT/delete_organization_users_result':
      return pipe(s, _l.organizationUpdateResult.set(a.payload))
    case 'ACCOUNT/get_balance_triggers':
      return pipe(
        s,
        _l.balanceTriggers.set(
          memoed.step<{ data: t.BalanceTrigger[]; count: number }>(Loading)
        )
      )
    case 'ACCOUNT/get_balance_trigger_result':
      return pipe(s, _l.balanceTriggers.set(memoed.step(a.payload)))
    case 'ACCOUNT/add_balance_trigger':
      return pipe(s, _l.addBalanceTriggerResult.set(Loading))
    case 'ACCOUNT/add_balance_trigger_result':
      return pipe(s, _l.addBalanceTriggerResult.set(a.payload))
    case 'ACCOUNT/update_balance_trigger':
      return pipe(s, _l.updateBalanceTriggerResult.set(Loading))
    case 'ACCOUNT/update_balance_trigger_result':
      return pipe(s, _l.updateBalanceTriggerResult.set(a.payload))
    case 'ACCOUNT/delete_balance_trigger':
      return pipe(s, _l.deleteBalanceTriggerResult.set(Loading))
    case 'ACCOUNT/delete_balance_trigger_result':
      return pipe(s, _l.deleteBalanceTriggerResult.set(a.payload))
    case 'ACCOUNT/add_phonenumber':
      return pipe(s, _l.addPhonenumberResult.set(Loading))
    case 'ACCOUNT/add_phonenumber_result':
      return pipe(s, _l.addPhonenumberResult.set(a.payload))
    case 'ACCOUNT/verify_phonenumber':
      return pipe(s, _l.verifyPhonenumberResult.set(Loading))
    case 'ACCOUNT/verify_phonenumber_result':
      return pipe(s, _l.verifyPhonenumberResult.set(a.payload))
    case 'ACCOUNT/upload_image':
      return pipe(s, _l.uploadImageResult.set(Loading))
    case t.ActionsNames.getWalletsAction:
      return { ...s, wallets: Loading }
    case t.ActionsNames.getWalletsResultAction:
      return { ...s, wallets: a.payload }
    case 'ACCOUNT/upload_image_result':
      return pipe(
        s,
        _l.uploadImageResult.set(a.payload),
        _l.selectedFile.set((f) =>
          pipe(
            a.payload,
            result.caseOf({
              Err: () => f,
              Ok: (): option.Option<File> => option.none,
            })
          )
        )
      )
    case '@@router/LOCATION_CHANGE':
      return pipe(s, _l.updateResult.set(initialState.updateResult))
    default:
      return s
  }
}

function* updateSaga(
  a: t.UpdateAccountAction,
  token: CancelToken
): Generator<any, void, any> {
  const previous: State['prosperus']['account'] = yield effects.select(
    (s: State) => s.prosperus.account
  )

  const account = pipe(previous, loadable.toOption)
  if (option.isNone(account)) {
    return
  }
  const accountUpdate = { ...account.value, ...a.payload }

  console.log('saga', accountUpdate)

  yield yield pipe(
    api.updateAccount(accountUpdate, token),
    task.map(foldEitherToAction(t.updateAccountResult)),
    task.map((a) =>
      a.payload._tag === 'Err'
        ? effects.all([effects.put(a)])
        : effects.all([effects.put(a), effects.put(getAccountAction())])
    ),
    (e) => effects.call(e)
  )
}

function* getOrganizationUserSaga(
  a: t.GetOrganizationUsers,
  token: CancelToken
): Generator<any, void, any> {
  const ws: Workspace = yield waitForWorkspace()

  if (ws.type === 'personal') {
    return
  }

  yield yield effects.call(
    pipe(
      api.getOrganizationsUsers(ws.organization.name, a.payload, token),
      task.map(foldEitherToAction(t.getOrganizationUsersResultAction)),
      task.map((a) => effects.put(a))
    )
  )
}

function* postOrganizationUserSaga(
  a: t.PostOrganizationUsers,
  token: CancelToken
): Generator<any, void, any> {
  const ws: Workspace = yield waitForWorkspace()
  if (ws?.type === 'organization') {
    const org: Organization = ws.organization
    yield yield effects.call(
      pipe(
        a.payload.type === 'new'
          ? api.addOrganizationUser(
              org.name,
              a.payload.user,
              a.payload.appName,
              token
            )
          : api.putOrganizationsUsers(org.name, a.payload.user, token),
        task.map(foldEitherToAction(t.postOrganizationUsersResultAction)),
        task.map((a) => effects.put(a))
      )
    )
  }
}

function* putOrganizationUserSaga(
  a: t.PutOrganizationUsers
): Generator<any, void, any> {
  const ws: Workspace = yield waitForWorkspace()
  const user: UAAAccount | undefined = yield waitForAccount()
  if (ws?.type === 'organization' && !!user) {
    const org: Organization = ws.organization
    yield yield effects.call(
      pipe(
        api.putOrganizationsUsers(org.name, a.payload),
        task.map(foldEitherToAction(t.putOrganizationUsersResultAction)),
        task.map((res) =>
          a.payload.login === user.login
            ? effects.all([effects.put(getAccountAction()), effects.put(res)])
            : effects.put(res)
        )
      )
    )
  }
}

function* deleteOrganizationUserSaga(
  a: t.DeleteOrganizationUser,
  token: CancelToken
): Generator<any, void, any> {
  const ws: Workspace = yield waitForWorkspace()

  if (ws?.type === 'organization') {
    const org: Organization = ws.organization
    yield yield effects.call(
      pipe(
        api.deleteOrganizationUser(org?.name, a.payload, token),
        task.map(foldEitherToAction(t.deleteOrganizationUserResultAction)),
        task.map((a) => effects.put(a))
      )
    )
  }
}

function* changePasswordSaga(
  a: t.ChangePasswordAction,
  token: CancelToken
): Generator<any, void, any> {
  yield yield effects.call(
    pipe(
      api.changePassword(a.payload, token),
      task.map(foldEitherToAction(t.changePasswordResult)),
      task.map((a) => effects.put(a))
    )
  )
}

function* uploadFileSaga(
  a: t.UploadImageAction,
  token: CancelToken
): Generator<any, void, any> {
  yield yield pipe(
    api.uploadFile(a.payload.file, token),
    task.map(
      either.fold(
        (e) => [t.uploadImageResultAction.err(e)],
        () => [t.uploadImageResultAction.ok(undefined), getAccountAction()]
      )
    ),
    task.map(array.map((a) => effects.put(a))),
    task.map((ps) => effects.all(ps)),
    (e) => effects.call(e)
  )
}

function* getBalanceTriggersSaga(
  { payload: { page, itemsPerPage } }: t.GetBalanceTriggers,
  token: CancelToken
): Generator<any, void, any> {
  yield yield pipe(
    api.getBalanceTriggers(page, itemsPerPage, token),
    task.map(foldEitherToAction(t.getBalanceTriggerResultAction)),
    task.map((a) => effects.put(a)),
    (t) => effects.call(t)
  )
}

function* addBalanceTriggerSaga(
  a: t.AddBalanceTrigger,
  token: CancelToken
): Generator<any, void, any> {
  yield yield pipe(
    api.addBalanceTrigger(a.payload, token),
    task.map(foldEitherToAction(t.addBalanceTriggerResultAction)),
    task.map((a) => effects.put(a)),
    (t) => effects.call(t)
  )
}

function* updateBalanceTriggerSaga(
  a: t.UpdateBalanceTrigger,
  token: CancelToken
): Generator<any, void, any> {
  yield yield pipe(
    api.updateBalanceTrigger(a.payload, token),
    task.map(foldEitherToAction(t.updateBalanceTriggerResultAction)),
    task.map((a) => effects.put(a)),
    (t) => effects.call(t)
  )
}

function* deleteBalanceTriggerSaga(
  a: t.DeleteBalanceTrigger,
  token: CancelToken
): Generator<any, void, any> {
  yield yield pipe(
    api.deleteBalanceTrigger(a.payload, token),
    task.map(foldEitherToAction(t.deleteBalanceTriggerResultAction)),
    task.map((a) => effects.put(a)),
    (t) => effects.call(t)
  )
}

function* getOrganizationWalletsSaga(
  a: t.GetWalletsAction,
  token: CancelToken
): Generator<any, void, any> {
  const ws: Workspace = yield waitForWorkspace()

  yield yield pipe(
    api.getOrganizationWallets(
      {
        ...a.payload,
        organizationName:
          ws?.type === 'organization' ? ws.organization?.name : '',
      },
      token
    ),
    task.map(foldEitherToAction(t.getWalletsResultAction)),
    task.map((a) => effects.put(a)),
    (t) => effects.call(t)
  )
}

function* addPhonenumberSaga(
  a: t.AddPhonenumber,
  token: CancelToken
): Generator<any, void, any> {
  yield yield pipe(
    api.addPhonenumber(a.payload, token),
    task.map(foldEitherToAction(t.addPhonenumberResultAction)),
    task.map((a) => effects.put(a)),
    (t) => effects.call(t)
  )
}

function* verifyPhonenumberSaga(
  a: t.VerifyPhonenumber,
  token: CancelToken
): Generator<any, void, any> {
  yield yield pipe(
    api.verifyPhonenumber(a.payload, token),
    task.map(foldEitherToAction(t.verifyPhonenumberResultAction)),
    task.map((a) =>
      effects.all([effects.put(getAccountAction()), effects.put(a)])
    ),
    (t) => effects.call(t)
  )
}

export function* saga() {
  yield effects.all([
    takeLatest('ACCOUNT/update', updateSaga),
    takeLatest('ACCOUNT/change_password', changePasswordSaga),
    takeLatest('ACCOUNT/get_organization_users', getOrganizationUserSaga),
    takeLatest('ACCOUNT/post_organization_users', postOrganizationUserSaga),
    takeEvery('ACCOUNT/put_organization_users', putOrganizationUserSaga),
    takeLatest('ACCOUNT/delete_organization_users', deleteOrganizationUserSaga),
    takeLatest('ACCOUNT/upload_image', uploadFileSaga),
    takeLatest(t.ActionsNames.getWalletsAction, getOrganizationWalletsSaga),
    takeLatest('ACCOUNT/get_balance_triggers', getBalanceTriggersSaga),
    takeLatest('ACCOUNT/add_balance_trigger', addBalanceTriggerSaga),
    takeLatest('ACCOUNT/update_balance_trigger', updateBalanceTriggerSaga),
    takeLatest('ACCOUNT/delete_balance_trigger', deleteBalanceTriggerSaga),
    takeLatest('ACCOUNT/add_phonenumber', addPhonenumberSaga),
    takeLatest('ACCOUNT/verify_phonenumber', verifyPhonenumberSaga),
  ])
}
