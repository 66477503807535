import { foldEitherToAction, getAccountAction } from '@library/react-toolkit'
import { CancelToken } from 'axios'
import { array, either, option, task } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import { lens } from 'lens.ts'
import { Action } from 'redux'
import * as effects from 'redux-saga/effects'

import { takeLatest } from '../../../shared/state/saga'
import { Loading, NotRequested } from '../../../shared/types'

import * as api from './api'
import * as t from './types'

export const initialState: t.State = {
  redirect: option.none,
  loginResult: NotRequested,
  signUpResult: NotRequested,
  createOrganizationResult: NotRequested,
  signUpMode: { type: 'default' },
}

const _l = lens<t.State>()

export const reducer = (s = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'ACCOUNT/login':
      return pipe(s, _l.loginResult.set(Loading))
    case 'ACCOUNT/sign_up':
      return pipe(s, _l.signUpResult.set(Loading))
    case 'ACCOUNT/login_with_google':
      return pipe(s, _l.loginResult.set(Loading))
    case 'ACCOUNT/sign_up_result':
      return pipe(s, _l.signUpResult.set(a.payload))
    case 'ACCOUNT/update_result':
      return pipe(s)
    case 'ACCOUNT/login_result':
      return pipe(s, _l.loginResult.set(a.payload))
    case 'ACCOUNT/set_signup_mode':
      return pipe(s, _l.signUpMode.set(a.payload))
    case 'ACCOUNT/create_organization':
      return pipe(s, _l.createOrganizationResult.set(Loading))
    case 'ACCOUNT/create_organization_result':
      return pipe(s, _l.createOrganizationResult.set(a.payload))
    case '@@router/LOCATION_CHANGE':
      return pipe(
        s,
        _l.loginResult.set(initialState.loginResult),
        (s) =>
          pipe(
            new URLSearchParams(a.payload.location.search).get('redirect'),
            option.fromNullable,
            option.map((value) => _l.redirect.set(option.some(value))(s)),
            option.getOrElse(() => s)
          ),
        _l.createOrganizationResult.set(NotRequested),
        _l.signUpResult.set(NotRequested)
      )
    default:
      return s
  }
}

function* loginSaga(
  a: t.LoginAction,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  yield yield effects.call(
    pipe(
      api.signIn(a.payload, token),
      task.map(
        either.fold<Error, t.LoginStatus, Action[]>(
          (r): Action[] => [t.loginResultAction.err(r)],
          (r): Action[] =>
            r === '2FA'
              ? [t.loginResultAction.ok(r)]
              : [getAccountAction(), t.loginResultAction.ok(r)]
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((es) => effects.all(es))
    )
  )
}

function* signUpSaga(
  a: t.SignUpAction,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  let org = a.payload.organization
  console.log('organization', JSON.stringify(org))
  if (!!org) {
    org.displayName = org.name
  }
  let req = a.payload
  console.log('req', JSON.stringify(req))

  yield yield pipe(
    api.signup({ ...req, organization: org }, token),
    task.map(foldEitherToAction(t.signUpActionResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  )
}

function* createOrganizationSaga(
  a: t.CreateOrganizationAction,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  yield yield pipe(
    api.createOrganization(
      {
        ...a.payload,
        displayName: a.payload.name,
      },
      token
    ),
    task.map(foldEitherToAction(t.createOrganizationResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  )
}

function* googleLoginSaga(
  a: t.LoginWithGoogleAction,
  token: CancelToken
): Generator<effects.CallEffect, void, effects.CallEffect> {
  const params = new URLSearchParams()

  params.append('google_id', a.payload.googleID)
  params.append('grant_type', a.payload.grant_type)

  yield yield pipe(
    api.googleSignIn(params, token),
    task.map(
      either.fold(
        (e) => [t.loginResultAction.err(e)],
        (r) => [getAccountAction(), t.loginResultAction.ok(r)]
      )
    ),
    task.map(array.map((a) => effects.put(a))),
    task.map((es) => effects.all(es)),
    (e) => effects.call(e)
  )
}

export function* saga() {
  yield effects.all([
    takeLatest('ACCOUNT/login_with_google', googleLoginSaga),
    takeLatest('ACCOUNT/sign_up', signUpSaga),
    takeLatest('ACCOUNT/create_organization', createOrganizationSaga),
    takeLatest('ACCOUNT/login', loginSaga),
  ])
}
