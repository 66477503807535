import { EditOutlined } from '@ant-design/icons'
import { memoed } from '@library/react-toolkit'
import { Button, Divider, Form, Modal, Steps } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../../shared/state/store'

import { TranslatedMessage } from '../../../../../shared/translations/data'
import { BalanceTrigger, updateBalanceTrigger } from '../../../types'
import { Step1, Step2 } from './NotificationSteps'
type Step = 0 | 1

const EditNotification = ({ trigger }: { trigger: BalanceTrigger }) => {
  const dispatch = useDispatch()
  const result = useSelector(
    (s) => s.accountSetting.updateBalanceTriggerResult._tag
  )

  const units = useSelector((s: State) => memoed.extract(s.units))

  const [visible, setVisible] = React.useState(false)
  const [step, setStep] = React.useState<Step>(0)
  const [updateState, setEditState] =
    React.useState<typeof result>('NotRequested')
  const [form] = Form.useForm<BalanceTrigger>()

  const startCreation = () => {
    setVisible(true)
    setStep(0)
    form.resetFields()
    setEditState('NotRequested')
  }

  React.useEffect(() => {
    if (updateState === 'Loading' && result === 'Ok') {
      form.resetFields()
      setEditState('NotRequested')
      setVisible(false)
      setStep(0)
    } else {
      setEditState(result)
    }
  }, [result, updateState])

  return (
    <>
      <Button icon={<EditOutlined />} onClick={startCreation} />
      <Modal
        title={<TranslatedMessage id="updateNotification" values={trigger} />}
        visible={visible}
        onCancel={() => {
          form.resetFields()
          setStep(0)
          setVisible(false)
        }}
        okButtonProps={{ loading: result === 'Loading' }}
        okText={
          step === 0 ? (
            <TranslatedMessage id="next" />
          ) : (
            <TranslatedMessage id="confirm" />
          )
        }
        okType="primary"
        onOk={() => {
          if (step === 0) {
            form
              .validateFields(['name', 'module'])
              .then(() => setStep(1))
              .catch((e) => console.debug(e))
          } else {
            form.submit()
          }
        }}
      >
        <Steps
          current={step}
          onChange={(step) => {
            step === 0 ? setStep(0) : setStep(1)
          }}
          style={{
            color: 'green',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <Steps.Step title="" />
          <Steps.Step title="" />
        </Steps>
        <Form
          id="notificationEditForm"
          form={form}
          size="small"
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            ...trigger,
            amount: trigger.amount / units[trigger.asset].divider,
          }}
          onFinish={(values) =>
            dispatch(
              updateBalanceTrigger({
                ...values,
                amount: values.amount * units[trigger.asset].divider,
                uuid: trigger.uuid,
              })
            )
          }
        >
          <Divider />
          {step === 0 ? <Step1 disableModule /> : <Step2 />}
        </Form>
      </Modal>
    </>
  )
}

export default EditNotification
