import { memoed } from '@library/react-toolkit'
import { Button, Divider, Form, Modal, Steps } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../../shared/state/store'

import { TranslatedMessage } from '../../../../../shared/translations/data'
import { BalanceTrigger, addBalanceTrigger } from '../../../types'
import { Step1, Step2 } from './NotificationSteps'

const NotificationAddModal = () => {
  const result = useSelector(
    (s) => s.accountSetting.addBalanceTriggerResult._tag
  )

  const units = useSelector((s: State) => memoed.extract(s.units))

  const dispatch = useDispatch()

  const [visible, setVisible] = React.useState(false)
  const [form] = Form.useForm<BalanceTrigger>()
  const [step, setStep] = React.useState(0)
  const [addState, setAddState] = React.useState<typeof result>('NotRequested')

  const startCreation = () => {
    setVisible(true)
    setStep(0)
    form.resetFields()
    setAddState('NotRequested')
  }

  React.useEffect(() => {
    if (addState === 'Loading' && result === 'Ok') {
      form.resetFields()
      setAddState('NotRequested')
      setVisible(false)
      setStep(0)
    } else {
      setAddState(result)
    }
  }, [result, addState])

  return (
    <>
      <Button type="primary" onClick={startCreation}>
        <TranslatedMessage id="addNotification" />
      </Button>
      <Modal
        title={<TranslatedMessage id="newNotification" />}
        visible={visible}
        onCancel={() => {
          form.resetFields()
          setStep(0)
          setVisible(false)
        }}
        okButtonProps={{
          loading: result === 'Loading',
        }}
        okText={
          step === 0 ? (
            <TranslatedMessage id="next" />
          ) : (
            <TranslatedMessage id="confirm" />
          )
        }
        okType="primary"
        onOk={() => {
          if (step === 0) {
            form
              .validateFields(['name', 'module'])
              .then(() => setStep(1))
              .catch((e) => console.debug(e))
          } else {
            form.submit()
          }
        }}
      >
        <Steps
          current={step}
          style={{
            color: 'green',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <Steps.Step title="" />
          <Steps.Step title="" />
        </Steps>
        <Form
          id="notificationAddForm"
          form={form}
          size="small"
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onValuesChange={(c, v) => console.debug(c, v)}
          onFinish={(trigger) => {
            trigger.amount = trigger.amount * units[trigger.asset].divider
            dispatch(addBalanceTrigger(trigger))
          }}
        >
          <Divider />
          {step === 0 ? <Step1 /> : <Step2 />}
        </Form>
      </Modal>
    </>
  )
}

export default NotificationAddModal
