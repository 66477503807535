import { Card, Tabs } from 'antd'
import { push } from 'connected-react-router'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { TranslatedMessage } from '../../../shared/translations/data'
import BackHome from '../../../shared/widgets/BackHome'
import NotificationAndAlerts from './NotificationsAndAlerts/NotificationAndAlerts'
import PersonalInformationForm from './PersonalInformationForm'
import SecuritySettingsForm from './securitySettingsForm'

const CenteredTabs = styled(Tabs)`
  & .ant-tabs-content-holder {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  & .ant-tabs-content {
    max-width: 1280px;
  }
`

const PersonalSetting = () => {
  const dispatch = useDispatch()
  const { tab } = useParams<{ tab?: string }>()

  return (
    <div>
      <BackHome />
      <Card title={<TranslatedMessage id="personalSetting" />}>
        <CenteredTabs
          onChange={(tabKey) => dispatch(push(`/personal_setting/${tabKey}`))}
          defaultActiveKey={tab ?? 'personal-information'}
          activeKey={tab ?? 'personal-information'}
        >
          <Tabs.TabPane
            tab={<TranslatedMessage id="personnalInformation" />}
            key="personal-information"
          >
            <PersonalInformationForm />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<TranslatedMessage id="SecurityInformation" />}
            key="security-information"
          >
            <SecuritySettingsForm />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<TranslatedMessage id="notificationsAndAlerts" />}
            key="notifications-alerts"
          >
            <NotificationAndAlerts />
          </Tabs.TabPane>
        </CenteredTabs>
      </Card>
    </div>
  )
}

export default PersonalSetting
