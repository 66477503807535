import { Input } from 'antd'
import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
} from 'react'
import { getCountryCallingCode } from 'react-phone-number-input'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  country?: string
  onChange?: (_: string) => void
}

export const PhonenumberInput = React.forwardRef<any, Props>(
  ({ onChange, country, value, onBlur, onFocus }, ref) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (!!onChange) {
          onChange(e.target.value)
        }
      },
      [onChange]
    )

    let prefix: string
    try {
      if (
        typeof value === 'string' &&
        value?.length > 0 &&
        value?.[0] === '+'
      ) {
        prefix = ''
      } else {
        const code = getCountryCallingCode(country ?? '')
        console.debug('got code', code)
        prefix = '+' + code
      }
    } catch (e) {
      console.debug('got exception', e)
      prefix = ''
    }

    return (
      <Input
        id="phone"
        prefix={prefix}
        name="phonenumber"
        style={{
          width: '200px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        defaultValue={value}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
        type="tel"
        autoComplete="tel"
      />
    )
  }
)
