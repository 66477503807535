import { loadable } from '@library/react-toolkit'
import { Spin } from 'antd'
import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const FullPageSpinStyle = styled(Spin)`
  height: 100vh !important;
  max-height: unset !important;
`

const FullPageSpin = ({ children }: { children?: React.ReactNode }) => {
  const isLoading = useSelector(
    (s) =>
      loadable.isLoading(s.prosperus.organization.currentWorkspace) ||
      loadable.isLoading(s.prosperus.account)
  )

  return <FullPageSpinStyle spinning={isLoading}>{children}</FullPageSpinStyle>
}

export default FullPageSpin
