import { LeftOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Form, Input, Typography } from 'antd'
import { option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import { Login, loginAction } from './types'

const LoginForm = styled.div`
  display: grid;
  grid-gap: 2px;
  overflow: hidden;
`

const StepContainer = styled.div`
  width: 200%;
  transform: translateX(${({ step }: { step: 0 | 1 }) => step * -50}%);
  transition: transform 0.2s ease-out;
  display: flex;
  & > *:first-child {
    transition: height 0.2s ease-out;
  }
  & > * {
    width: 50%;
    padding: 0 4px;
  }
`

const LoginHeader = styled(Typography.Title)`
  text-align: center;
  margin-bottom: 24px;
`

const ForgotButtons = styled.div`
  display: grid;
  margin-top: 24px;
  margin-bottom: 24px;
`

const LoginCard = styled(Card)`
  max-width: 512px;
`

const LoginComponent = () => {
  const [form] = Form.useForm()

  const messageReader = translatedMessage(useSelector((s: State) => s.locale))

  const loginFailed = useSelector((s: State) =>
    loadable.isErr(s.account.loginResult)
  )

  const loading = useSelector((s: State) =>
    loadable.isLoading(s.account.loginResult)
  )
  const step = useSelector((s: State) =>
    pipe(
      s.account.loginResult,
      loadable.toOption,
      option.map((v): 0 | 1 => (v === '2FA' ? 1 : 0)),
      option.getOrElse((): 0 | 1 => 0)
    )
  )
  const dispatch = useDispatch()

  return (
    <LoginCard>
      <LoginHeader level={4} style={{ marginTop: 14 }}>
        <TranslatedMessage id="login" />
      </LoginHeader>
      <Form<Login>
        onFinish={(e) => dispatch(loginAction(e))}
        form={form}
        style={{ marginTop: 32 }}
      >
        <LoginForm style={{ margin: 18 }}>
          <StepContainer step={step}>
            <div>
              <Form.Item
                name="username"
                id="username"
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="requiredUsername" />,
                  },
                ]}
              >
                <Input
                  autoComplete="username"
                  prefix={<UserOutlined />}
                  placeholder={messageReader('EmailOrUserName')}
                  type="Text"
                />
              </Form.Item>
              <Form.Item
                name="password"
                id="password"
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="requiredPassword" />,
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined />}
                  placeholder={messageReader('password')}
                  required={true}
                  autoComplete="password"
                  type="password"
                />
              </Form.Item>
            </div>
            <div>
              <Button type="link" onClick={() => form.resetFields()}>
                <LeftOutlined />
                <TranslatedMessage id="back" />
              </Button>
              <Form.Item
                name="tfacode"
                rules={[
                  {
                    required: step === 1,
                    message: <TranslatedMessage id="requiredCode" />,
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  prefix={<UserOutlined />}
                  placeholder={messageReader('tfacode')}
                />
              </Form.Item>
            </div>
          </StepContainer>
          {loginFailed && (
            <Alert
              message={loginFailed && <TranslatedMessage id="loginFailed" />}
              type="error"
            />
          )}
          <Button
            htmlType="submit"
            type="primary"
            style={{ marginTop: 12, marginBottom: '8px' }}
            loading={loading}
          >
            <TranslatedMessage id="login" />
          </Button>
        </LoginForm>
      </Form>
      <ForgotButtons>
        <Button disabled={loading} type="link">
          <Link to={step === 0 ? '/auth/reset/init' : '/auth/request-tfa'}>
            <TranslatedMessage
              id={step === 0 ? 'forgotPassword' : 'lostTFACode'}
            />
          </Link>
        </Button>
      </ForgotButtons>
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        <TranslatedMessage id="createAccountQuestion" />
        <Button type="link" color="primary">
          <Link to="/auth/signup?type=organization">
            <TranslatedMessage id="signUp" />
          </Link>
        </Button>
        <Button type="link" color="primary">
          <Link to="/auth/signup?type=bank">
            <TranslatedMessage id="signUpBank" />
          </Link>
        </Button>
      </Typography.Paragraph>
    </LoginCard>
  )
}

export default LoginComponent
