import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from 'connected-react-router'
import { console, io, ioEither } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import { createBrowserHistory } from 'history'
import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxSaga from 'redux-saga'
import * as effects from 'redux-saga/effects'

import * as notifications from '../Notifications'
import * as accountSetting from './../../components/Account'
import * as login from './../../components/Authentication/Login'
import * as resetPassword from './../../components/Authentication/ResetPassword'
import * as tfaRequest from './../../components/Authentication/TFAEmail'
import * as amConfig from './../../components/Account/organisationSettings/AssetManagementSettings'
import * as info from './Info'
import { getItem } from './io'
import * as locale from './Locale'
import * as localStorage from './LocalStorage'
import * as units from './Units'
import * as organization from './organization'
import {
  prosperusInitSaga,
  prosperusSaga,
  ProsperusActions,
  ProsperusState,
  prosperusReducer,
  NotRequested,
} from '@library/react-toolkit'

export type Actions =
  | locale.Actions
  | info.Actions
  | login.Actions
  | accountSetting.Actions
  | organization.Actions
  | ProsperusActions
  | amConfig.Actions

function* initSaga() {
  yield effects.all([
    effects.fork(info.infoInit),
    effects.fork(prosperusInitSaga, {
      allowPersonal: true,
      activeModules: ['SMART_HUB'],
    }),
  ])
}

function* rootSaga() {
  yield effects.fork(initSaga)
  yield effects.all([
    localStorage.saga(),
    login.saga(),
    resetPassword.saga(),
    accountSetting.saga(),
    tfaRequest.saga(),
    units.saga(),
    locale.saga(),
    organization.saga(),
    amConfig.saga(),
    prosperusSaga(),
  ])
}

const sagaMiddleware = reduxSaga()
export const history = createBrowserHistory({ basename: '/samoon-accounts' })

export interface State {
  prosperus: ProsperusState
  info: info.State
  account: login.State
  accountSetting: accountSetting.State
  tfaRequest: tfaRequest.State
  locale: locale.State
  resetPassword: resetPassword.State
  router: RouterState
  units: units.State
  notifications: notifications.State
  organization: organization.State
  amConfig: amConfig.State
}

declare module 'react-redux' {
  interface DefaultRootState extends State {}
}

const loadState: io.IO<Partial<State>> = pipe(
  getItem('info', info.StateC),
  ioEither.map((info) => ({ info })),
  ioEither.getOrElse((l) =>
    pipe(
      console.warn('bad state'),
      io.chain(() => console.warn(l)),
      io.map((): Partial<State> => ({}))
    )
  )
)

const appReducer = combineReducers<State | undefined>({
  info: info.reducer,
  account: login.reducer,
  accountSetting: accountSetting.reducer,
  tfaRequest: tfaRequest.reducer,
  locale: locale.reducer,
  resetPassword: resetPassword.reducer,
  units: units.reducer,
  notifications: notifications.reducer,
  router: connectRouter(history),
  organization: organization.reducer,
  amConfig: amConfig.reducer,
  prosperus: (s, a) =>
    prosperusReducer(
      s ?? {
        account: NotRequested,
        organization: {
          currentWorkspace: NotRequested,
          selectModalActive: false,
        },
        units: NotRequested,
        dcnNymID: NotRequested,
      },
      a
    ),
})

const rootReducer = (state: State | undefined, action: AnyAction) => {
  if (action.type === 'ACCOUNT/logout_result') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const store = createStore(
  rootReducer,
  // @ts-ignore
  loadState(),
  composeWithDevTools({ trace: true })(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  )
)

sagaMiddleware.run(rootSaga)

export default store
