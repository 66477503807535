import * as React from 'react'
import * as t from 'io-ts'
import { FormattedMessage } from 'react-intl'
import { record } from 'fp-ts'
import { addMessages } from '@library/react-toolkit'
import english from './translations-en.json'
import french from './translations-fr.json'

type LocalMessageID = keyof typeof english
type LocalMessages = { [K in LocalMessageID]: string }

const localData: { [K in 'en' | 'fr']: LocalMessages } = {
  en: english,
  fr: french,
}

const data = addMessages(localData)

export const Locale = t.keyof(data)
export type Locale = t.TypeOf<typeof Locale>

export type MessageID = keyof typeof data['en']
export type Messages = { [K in MessageID]: string }

type MessageProps = React.ComponentProps<typeof FormattedMessage> & {
  id: MessageID
  msgProsp?: string
}

export const locales = record.keys(data)

export const translatedMessage = (locale: Locale) => (id: MessageID) =>
  data[locale][id]
export const TranslatedMessage = (p: MessageProps) => (
  <FormattedMessage {...p} />
)

export default data
