import { ConnectedRouter } from 'connected-react-router'
import * as React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './components/App'
import store, { history } from './shared/state/store'

const queryClient = new QueryClient()
render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
)
