import {
  PayloadAction,
  payloadActionCreator,
  Loadable,
  FailableAction,
  failableActionCreator,
} from '../../../shared/types'
import { LocationChangeAction } from 'connected-react-router'

export type State = {
  requestResult: Loadable<unknown>
}

export type RequestTFAAction = PayloadAction<'TFA/request', { email: string }>
export const requestTFAAction =
  payloadActionCreator<RequestTFAAction>('TFA/request')

export type RequestTFAResult = FailableAction<'TFA/request_result', undefined>
export const requestTFResult =
  failableActionCreator<RequestTFAResult>('TFA/request_result')

export type Actions = RequestTFAAction | RequestTFAResult | LocationChangeAction
