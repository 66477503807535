"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.optionFromNullable = void 0;
/**
 * @since 0.5.0
 */
var pipeable_1 = require("fp-ts/lib/pipeable");
var Either_1 = require("fp-ts/lib/Either");
var O = __importStar(require("fp-ts/lib/Option"));
var t = __importStar(require("io-ts"));
var option_1 = require("./option");
/**
 * @since 0.5.0
 */
function optionFromNullable(codec, name) {
    if (name === void 0) { name = "Option<" + codec.name + ">"; }
    return new t.Type(name, option_1.option(codec).is, function (u, c) {
        return u == null
            ? t.success(O.none)
            : pipeable_1.pipe(codec.validate(u, c), Either_1.map(O.some));
    }, function (a) {
        return O.toNullable(pipeable_1.pipe(a, O.map(codec.encode)));
    });
}
exports.optionFromNullable = optionFromNullable;
