import { Loadable } from '@library/react-toolkit'
import { task } from 'fp-ts'
import * as t from 'io-ts'

export const AMApprovalConfigBody = t.type({
  minimum: t.number,
})
export type AMApprovalConfigBody = t.TypeOf<typeof AMApprovalConfigBody>

export const AMApprovalConfig = t.type({
  minimum: t.number,
  organization: t.string,
  createdAt: t.string,
  updatedAt: t.string,
})
export type AMApprovalConfig = t.TypeOf<typeof AMApprovalConfig>

export type State = {
  config: Loadable<AMApprovalConfig>
}

export type TaskInnerType<T> = T extends task.Task<infer R> ? R : never
